import React, { useEffect } from 'react';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowResetButtonsSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { setFitStatus, setShowFitAssistant, setShowResetButtons, setStepEnum } from '../../Slices/FitAssistantSlice';
import { resetFitAssistant } from '../../Thunks/Reset';
import { trackNoFit, trackView } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const NoFitPlatforms = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const translations = useAppSelector(TranslationsSelector);
  const showResetButtons = useAppSelector(ShowResetButtonsSelector);

  useEffect(() => {
    trackNoFit(initialData.FitAssistantVariation, initialData.IsYearFirst, carInformation);
    trackView(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  }, []);

  const onClickPrimary = () => {
    dispatch(setShowFitAssistant(false));
    dispatch(setShowResetButtons(true));
  };

  const onclickSecondary = () => dispatch(resetFitAssistant());

  const onExistingRackClick = () => {
    dispatch(setFitStatus(FitAssistantStatus.NoSelection));
    dispatch(setStepEnum(FitAssistantStep.RackMounting));
  };

  return (
    <>
      <div>
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{
            __html: currentStepContent?.Text ?? '',
          }}
        />
        {currentStepContent?.ImageUrl && <img src={currentStepContent?.ImageUrl} alt={currentStepContent?.ImageAlt} />}
        <div className="rack-selector d-flex flex-column justify-content-center">
          <div className="rack-options mt-6" onClick={onExistingRackClick}>
            <div className="rack-option d-flex mb-4 px-1 py-1">
              <div className="image-wrapper d-flex justify-content-center w-100"></div>
              <div className="info d-flex flex-column justify-content-center">
                <h5 className="mb-1">{translations.mountOnExistingRack}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Buttons visible>
        <PrimaryButton visible text={translations.close} onClick={onClickPrimary} />
        <SecondaryButton visible={showResetButtons} text={translations.reset} onClick={onclickSecondary} />
      </Buttons>
    </>
  );
};

export default NoFitPlatforms;
