import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepDataSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ProductDetailsSelector,
  ShowMoreInfoSelector,
  ShowResetButtonsSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import {
  addCarInformation,
  selectOption,
  setFitStatus,
  setFooterIsFixed,
  setShowFitAssistant,
  setShowResetButtons,
} from '../../Slices/FitAssistantSlice';
import { CookieHelper } from '../../Helpers/CookieHelper';
import { CarInformation, StepDataData } from '../../Types/FitAssistantData';
import { getPerCategorySearchString, getSearchString } from '../../../FitAssistantShared/SearchString';
import { IsProductPage } from '../../../FitAssistantShared/IsProductPage';
import { resetFitAssistant } from '../../Thunks/Reset';
import { SetFitStatusForAllProducts } from '../../../FitAssistantShared/Functions';
import { getOptions } from '../../Thunks/Queries';
import { onFitAssistantSaveData } from '../../../FitAssistant/Util/Tracking';
import { FooterHelper } from '../../Helpers/MoreInfoHelper';
import { ProductPageHelper } from '../../Helpers/ProductPageHelper';
import { trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import RackOptionItem from './RackOptionItem';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';

import './RackSelector.less';

const PlatformsRackSelector = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepData = useAppSelector(CurrentStepDataSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const showResetButton = useAppSelector(ShowResetButtonsSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const translations = useAppSelector(TranslationsSelector);
  const productDetail = useAppSelector(ProductDetailsSelector);

  useEffect(() => {
    if (
      !currentStepData?.data ||
      currentStepData?.data?.length === 0 ||
      !currentStepData?.data?.find((x) => x.Selected)
    ) {
      dispatch(getOptions());
    } else {
      SetFitStatusForAllProducts(FitAssistantStatus.Confirmed);
      dispatch(setFitStatus(FitAssistantStatus.Confirmed));
      dispatch(setShowResetButtons(true));

      if (IsProductPage) {
        ProductPageHelper.HandleProductPageData(currentStepData, productDetail, initialData, carInformation, dispatch);
      }
    }
  }, []);

  useEffect(() => {
    let result = FooterHelper.FooterShouldBeFixed('.rack-selector');
    dispatch(setFooterIsFixed(result));

    return () => {
      dispatch(setFooterIsFixed(false));
    };
  }, [currentStepData]);

  const getCarInformation = (option: StepDataData): CarInformation[] => {
    const configItem = currentStepData?.configItems;
    const variation: CarInformation = {
      parameterName: currentStep,
      configurationItem: configItem ?? '',
      text: option.Text,
      value: option.Value,
    };

    let filteredCarinformation = carInformation.filter((x) => x.parameterName != currentStep);
    let updCarInformation: CarInformation[] = [...filteredCarinformation, variation];
    return updCarInformation;
  };

  const handleOnClickDefault = (): void => {
    const selection: string = getSearchString(carInformation);
    const perCategorySelection: string = getPerCategorySearchString(carInformation);

    CookieHelper.SetCookies(selection, perCategorySelection);

    SetFitStatusForAllProducts(FitAssistantStatus.Confirmed);
    dispatch(setFitStatus(FitAssistantStatus.Confirmed));
    dispatch(setShowFitAssistant(false));
    dispatch(setShowResetButtons(true));
    onFitAssistantSaveData(carInformation);

    if (IsProductPage) {
      ProductPageHelper.HandleProductPageData(currentStepData, productDetail, initialData, carInformation, dispatch);
    }

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  const handleOptionClick = (option: StepDataData, index: number): void => {
    if (!option.Selected) {
      dispatch(selectOption(index));
      dispatch(setShowResetButtons(false));
      dispatch(addCarInformation(getCarInformation(option)));
    }
  };

  return (
    <>
      {!showMoreInfo && (
        <>
          <div className="rack-selector d-flex flex-column justify-content-center">
            <div
              className="info-text"
              dangerouslySetInnerHTML={{
                __html: currentStepContent?.Text ?? '',
              }}
            />
            <h5>{translations.selectRoofRack}</h5>
            <div className="rack-options mt-6">
              {currentStepData?.data?.map((option, index) => {
                return <RackOptionItem key={index} option={option} onClick={() => handleOptionClick(option, index)} />;
              })}
            </div>
          </div>
          <Buttons visible={currentStepData?.data?.some((x) => x.Selected) ?? false}>
            <PrimaryButton visible text={translations.confirm} onClick={handleOnClickDefault} />
            <SecondaryButton
              visible={showResetButton}
              text={translations.reset}
              onClick={() => dispatch(resetFitAssistant())}
            />
          </Buttons>
        </>
      )}
    </>
  );
};

export default PlatformsRackSelector;
