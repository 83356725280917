import { useLayoutEffect, useState } from 'react';

export const useOnResize = () => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    let timer;

    setWidth(window.innerWidth);

    const updateWidth = () => {
      clearTimeout(timer);
      timer = setTimeout(setWidth(window.innerWidth), 100);
    };

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  });

  return width;
};
