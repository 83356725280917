import React from 'react';
import { useAppSelector } from '../Store/store';
import { CurrentStepSelector } from '../Selectors/FitAssistantSelectors';
import FitAssistantStep from '../Enums/FitAssistantStep';
import ThirdPartySelector from '../Components/ThirdPartySelector/ThirdPartySelector';
import GenericRackSelector from '../Components/RackSelector/GenericRackSelector';
import NoFit from '../Components/NoFit/NoFit';
import NoRackFit from '../Components/NoRackFit/NoRackFit';
import RackNotCompatible from '../Components/RackNotCompatible/RackNotCompatible';
import RooftopTentsRackSelector from '../Components/RackSelector/RoofTopTentsRackSelector';
import RooftopTentsVariationSelector from '../Components/VariationSelector/RooftopTentsVariationSelector';
import SelectList from '../Components/SelectList/SelectList';
import Unconfirmed from '../Components/Unconfirmed/Unconfirmed';

export const RooftopTents = () => {
  const currentStep = useAppSelector(CurrentStepSelector);

  const RenderStep = () => {
    switch (currentStep) {
      case FitAssistantStep.Make:
      case FitAssistantStep.Model:
      case FitAssistantStep.Year:
        return <SelectList />;
      case FitAssistantStep.Variation:
        return <RooftopTentsVariationSelector />;
      case FitAssistantStep.GenericRoofRacks:
        return <GenericRackSelector />;
      case FitAssistantStep.RoofRacks:
        return <RooftopTentsRackSelector />;
      case FitAssistantStep.NoRackFit:
        return <NoRackFit />;
      case FitAssistantStep.EnsureThirdParty:
        return <ThirdPartySelector />;
      case FitAssistantStep.RackNotCompatible:
        return <RackNotCompatible />;
      case FitAssistantStep.NoFits:
        return <NoFit />;
      case FitAssistantStep.Unconfirmed:
        return <Unconfirmed />;
      default:
        return <></>;
    }
  };

  return <>{RenderStep()}</>;
};

export default RooftopTents;
