import React from 'react';
import { StepDataData } from '../../Types/FitAssistantData';

type SelectListItemForProductSearch = {
  item: StepDataData;
  isSelected: boolean;
  onSelect: (item: StepDataData) => void;
};

const SelectListItemForProductSearch = ({ item, isSelected, onSelect }: SelectListItemForProductSearch) => {
  return (
    <div className={`list-item select`} onClick={() => onSelect(item)}>
      <div className="list-item-text">
        <span className="header">{item.Text}</span>
        {isSelected && <i className="le-icon-check"></i>}
      </div>
    </div>
  );
};

export default SelectListItemForProductSearch;
