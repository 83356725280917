import { createSlice } from '@reduxjs/toolkit';

const initialData = { ...window._THULEDATA.ProductDetail };

let initialState = initialData;

export const ProductDetailSlice = createSlice({
  name: 'matchingProducts',
  initialState,
  reducers: {},
});

export default ProductDetailSlice.reducer;
