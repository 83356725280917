export class FooterHelper {
  public static FooterShouldBeFixed = (selector: string): boolean => {
    let moreInfoHeight = 68; // Total height of the move info element
    let moreInfoHeightFixed = 88; // Total height of the fixed move info element
    let selectorHeight = document.querySelector(selector)?.clientHeight;
    let modalContentHeight = document.querySelector('.modal-content-section')?.clientHeight;

    if (!selectorHeight || !modalContentHeight) return false;

    let result = selectorHeight - moreInfoHeight + moreInfoHeightFixed < modalContentHeight;
    return result;
  };
}
