import React, { useEffect, useState } from 'react';
import {
  CurrentStepSelector,
  ShowMoreInfoSelector,
  StepContentListSelector,
} from '../../Selectors/FitAssistantSelectors';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { Constants } from '../../Constants';
import { setFooterIsFixed, setStepEnum } from '../../Slices/FitAssistantSlice';
import { FooterHelper } from '../../Helpers/MoreInfoHelper';
import { ImageLoadEvent } from '../../Types/Events';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import MountingOption from './MountingOption';

import './MountingSelector.less';

const MountingSelector = () => {
  const [totalImageHeight, setTotalImageHeight] = useState<number>(0);

  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(CurrentStepSelector);
  const stepContentList = useAppSelector(StepContentListSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);

  useEffect(() => {
    document.addEventListener(Constants.Events.VARIATION_OPTION_IMAGE_LOAD, updateTotalImageHeight as EventListener);

    return () => {
      document.removeEventListener(
        Constants.Events.VARIATION_OPTION_IMAGE_LOAD,
        updateTotalImageHeight as EventListener
      );
    };
  }, []);

  useEffect(() => {
    let imageResult = FooterHelper.FooterShouldBeFixed('.mounting-selector');
    dispatch(setFooterIsFixed(imageResult));

    return () => {
      dispatch(setFooterIsFixed(false));
    };
  }, [totalImageHeight]);

  const updateTotalImageHeight = (e: CustomEvent<ImageLoadEvent>): void => {
    setTotalImageHeight((current) => current + e.detail.height);
  };

  return (
    <>
      {!showMoreInfo && (
        <div className="mounting-selector d-flex flex-column justify-content-center">
          <div className="mounting-options">
            {stepContentList
              ?.find((x) => x.Parameter === currentStep)
              ?.ContentOptions?.map((option, index) => {
                return (
                  <MountingOption
                    onClick={() => {
                      dispatch(setStepEnum(option.NextStep as FitAssistantStep));
                    }}
                    option={option}
                    key={index}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default MountingSelector;
