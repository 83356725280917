import React from 'react';
import { ButtonProps } from './Types';

const SecondaryButton = ({ visible, text, onClick }: ButtonProps) => {
  return (
    <>
      {visible && (
        <button className="btn thule-btn-secondary w-100" onClick={onClick}>
          {text}
        </button>
      )}
    </>
  );
};

export default SecondaryButton;
