export namespace Constants {
  export namespace Cookies {
    export const COOKIE_NAME: string = 'fitassistant';
    export const PER_CATEGORY_COOKIE_NAME: string = 'fitassistantpercategory';
  }

  export namespace Events {
    export const MOUNTING_OPTION_IMAGE_LOAD: string = 'mountingOptionImageLoad';
    export const VARIATION_OPTION_IMAGE_LOAD: string = 'variationOptionImageLoad';
  }

  export namespace FitTipClassifications {
    export const VEHICLE_SPECIFIC = 'VehicleSpecific';
  }

  export namespace Parameters {
    export const CONFIRM_COMPATIBILITY = 'confirmCompatibility';
  }

  export namespace SolutionTypes {
    export const PLATFORM: string = 'SolutionPlatform';
  }

  export namespace Tracking {
    export const CHOOSE_VARIATION: string = 'fit_assistant_choose_carvariation';
    export const CLOSE_CONFIRMATION: string = 'fit_assistant_close_confirmation';
    export const CLOSE_NOFIT: string = 'fit_assistant_close_nofit';
    export const CLOSE_UNCONFIRMED: string = 'fit_assistant_close_unconfirmed';
    export const EXIT: string = 'fit_assistant_exit';
    export const OK: string = 'fit_assistant_OK';
    export const RESET: string = 'fit_assistant_reset';
    export const OPEN_WITH_SELECTION: string = 'fit_assistant_open_value';
    export const OPEN_WITHOUT_SELECTION: string = 'fit_assistant_open_empty';
    export const VIEW: string = 'fit_assistant_view';
  }
}
