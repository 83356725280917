import React from 'react';
import { getOptions } from '../../Thunks/Queries';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { TranslationsSelector } from '../../Selectors/FitAssistantSelectors';
import Buttons from '../Buttons/Buttons';
import PrimaryButton from '../Buttons/PrimaryButton';

const ErrorComponent = () => {
  const dispatch = useAppDispatch();
  const translations = useAppSelector(TranslationsSelector);

  const onClickPrimary = () => {
    dispatch(getOptions());
  };

  return (
    <>
      <div>
        <p>{translations.loadingErrorMessage}</p>
      </div>
      <Buttons visible>
        <PrimaryButton visible={true} text={translations.reload} onClick={onClickPrimary} />
      </Buttons>
    </>
  );
};

export default ErrorComponent;
