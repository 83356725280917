import { createAsyncThunk } from '@reduxjs/toolkit';
import { CookieHelper } from '../Helpers/CookieHelper';
import { RootState } from '../Store/store';
import { Constants } from '../Constants';
import {
  getAdditionalFilterForOptions,
  getSearchParametersFromQuery,
  setQueryFromSearchParameters,
} from '../../FitAssistantShared/Functions';
import { FitAssistantOptionsResponse } from '../Types/FitAssistantOptionsResponse';
import { CarInformation, InitialData, Step, StepData } from '../Types/FitAssistantData';
import { getPerCategorySearchString, getSearchString } from '../../FitAssistantShared/SearchString';
import { OperationVariables, QueryOptions } from '@apollo/client';
import { GET_PRODUCT_QUERY } from '../Queries/Queries';
import { getWindowData } from '../../FitAssistantShared/GetWindowData';
import { client } from '../../../../../../../../../Foundation/SitecoreGraphQL/code/clientv2';
import { GetProductsQuery } from '../Types/FitAssistantProductsResponse';
import { addSelectedCarFromCookie, setShowFitAssistant, setStepEnum } from '../Slices/FitAssistantSlice';
import { ProductLoader } from '../Helpers/ProductLoader';
import { IsProductPage } from '../../FitAssistantShared/IsProductPage';
import { addMatchingProducts } from '../Slices/MatchingProductsSlice';
import { BodyData, RejectValue, SelectedCarFromCookie } from './Types';
import { resetFitAssistant } from './Reset';
import FitAssistantStep from '../Enums/FitAssistantStep';
import FitAssistantVariation from '../Enums/FitAssistantVariation';

export const loadOptionsFromCookie = createAsyncThunk<string, void, RejectValue>(
  'fitAssistant/loadOptionsFromCookie',
  async (_, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
    let state = getState() as RootState;
    let initialData = state.FitAssistant.initialData;
    let cookieData = await GetCookieData(initialData);

    if (cookieData.length === 0 || cookieData === null) {
      return fulfillWithValue('No cookie or query string set.');
    }

    const getArrayOrder = initialData.Steps.map((step) => step.Parameter);

    const sortArrayByListOrder = cookieData.sort(
      (a: FitAssistantOptionsResponse, b: FitAssistantOptionsResponse) =>
        getArrayOrder.indexOf(a.ParameterName) - getArrayOrder.indexOf(b.ParameterName)
    );

    const sliderList: StepData[] = sortArrayByListOrder.map((item) => ({
      parameters: item.ParameterName,
      configItems: item.NextConfigurationItem,
      data: item.Options,
      messages: item.OptionMessages,
    }));

    let selectedCar: CarInformation[] = GetSelectedCar(initialData, sortArrayByListOrder);
    selectedCar = selectedCar.filter((car) => car.parameterName !== FitAssistantStep.RoofRacks);

    let selectedCarError = GetCarErrors(initialData, sliderList, selectedCar);

    const searchString = getSearchString(selectedCar);

    const perCategorySearchString = getPerCategorySearchString(selectedCar);

    let noValueParameters = selectedCar.filter((x) => x.value === '0').map((y) => y.parameterName);
    let filteredSteps = initialData.Steps.filter(
      (x) => !x.SkipWhenLoadingFromCookie && !noValueParameters.includes(x.Parameter)
    );

    let currentStep: Step | undefined = filteredSteps.length > 0 ? filteredSteps[filteredSteps.length - 1] : undefined;

    if (!currentStep) return rejectWithValue('The initial data contained no step content.');

    if (selectedCarError.length === 0) {
      const getOptionsVariables: OperationVariables = {
        siteName: getWindowData().SitecoreSiteName,
        language: getWindowData().SitecoreLanguage,
        configurationItem: currentStep?.Id,
        selectedproductpimid: state.ProductDetail.ProductId || '',
        parameters: `${searchString},${perCategorySearchString}`,
        additionalFilter: getAdditionalFilterForOptions(currentStep, state.FitAssistant.initialData.AdditionalFilters),
        ispreview: state.FitAssistant.initialData.IsPreview,
      };
      const getOptions: QueryOptions = {
        fetchPolicy: 'network-only',
        query: GET_PRODUCT_QUERY,
        variables: getOptionsVariables,
      };

      let result = await client.query<GetProductsQuery>(getOptions);

      if (result.error) return rejectWithValue(result.error.message);

      let selectedCarFromCookie: SelectedCarFromCookie = {
        getSliderList: sliderList,
        getSelectedCar: selectedCar,
      };

      if (!currentStep && state.FitAssistant.initialData.Steps && state.FitAssistant.initialData.Steps.length > 0) {
        dispatch(setStepEnum(state.FitAssistant.initialData.Steps[0].Parameter));
      }

      if (selectedCarFromCookie && cookieData.length > 0 && result.data && selectedCarError?.length === 0) {
        dispatch(addSelectedCarFromCookie(selectedCarFromCookie));

        let productLoader = new ProductLoader(
          result.data,
          selectedCarFromCookie.getSelectedCar,
          selectedCarFromCookie,
          false,
          state.FitAssistant.initialData,
          state.FitAssistant.stepData,
          dispatch
        );

        productLoader.Load();

        if (IsProductPage)
          setQueryFromSearchParameters(initialData.FitAssistantCookieName, selectedCarFromCookie.getSelectedCar);

        dispatch(addMatchingProducts(result.data));

        let invalidCar =
          sortArrayByListOrder.filter(
            (x) =>
              ['make', 'model', 'year'].indexOf(x.ParameterName) > -1 && x.Options?.find((option) => option.Selected)
          )?.length < 3;

        if (invalidCar) {
          resetFitAssistant();
        }
      } else if (selectedCarError) {
        let firstNoValueStep = selectedCarFromCookie.getSelectedCar?.find((x) => x.value === '0');
        let stepToShow = firstNoValueStep
          ? selectedCarFromCookie.getSelectedCar[selectedCarFromCookie.getSelectedCar.indexOf(firstNoValueStep) - 1]
              .parameterName
          : selectedCarFromCookie.getSelectedCar[selectedCarFromCookie.getSelectedCar.length - 1].parameterName;

        dispatch(addSelectedCarFromCookie(selectedCarFromCookie));
        dispatch(setStepEnum(stepToShow));

        dispatch(setShowFitAssistant(false));
      }
    }
    return fulfillWithValue('Loading options from cookie or query string finished');
  }
);

async function GetCookieData(initialData: InitialData): Promise<FitAssistantOptionsResponse[]> {
  let cookies = CookieHelper.GetCookies();
  const getSelectedCarFromQuery = getSearchParametersFromQuery(initialData.FitAssistantCookieName);

  if (!getSelectedCarFromQuery && !cookies.cookie) return [];

  const bodydata = GetBodyData(initialData);

  let cookieData: FitAssistantOptionsResponse[] = await fetch(
    '/api/sitecore/fitguide/LoadOptionsFromQueryStringOrCookie',
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(bodydata),
    }
  )
    .then((response) => response.json())
    .catch();

  return cookieData;
}

function GetBodyData(initialData: InitialData): BodyData {
  let cookies = CookieHelper.GetCookies();
  const getSelectedCarFromQuery = getSearchParametersFromQuery(initialData.FitAssistantCookieName);

  let bodyData = {
    selectedCarFromCookieOrQuery:
      getSelectedCarFromQuery || JSON.stringify(`${cookies.cookie},${cookies.perCategoryCookie}`),
    steps: initialData.Steps.filter((x) => !x.SkipWhenLoadingFromCookie),
  };

  return bodyData;
}

function GetSelectedCar(initialData: InitialData, optionsResponse: FitAssistantOptionsResponse[]): CarInformation[] {
  let selectedCar: CarInformation[] = optionsResponse
    .map((item) => {
      let selectedItem = item.Options?.find((selectedItem) => selectedItem.Selected);
      return { item, selectedItem };
    })
    .map(({ item, selectedItem }) => {
      return {
        parameterName: item?.ParameterName,
        configurationItem: item.NextConfigurationItem,
        text: selectedItem && selectedItem.Text ? selectedItem.Text : 'no-value',
        value:
          selectedItem && selectedItem?.BedTypeLovId
            ? selectedItem.BedTypeLovId
            : selectedItem?.Value
              ? selectedItem.Value
              : '0',
      };
    });

  let bodyData = GetBodyData(initialData);

  if (bodyData.selectedCarFromCookieOrQuery.toLowerCase().includes(FitAssistantStep.ConfirmCompatibility)) {
    selectedCar.push({ parameterName: FitAssistantStep.ConfirmCompatibility, value: true } as CarInformation);
  }

  return selectedCar;
}

function GetCarErrors(initialData: InitialData, getSliderList: StepData[], selectedCar: CarInformation[]) {
  let errors = getSliderList
    .map((item) => {
      let selectedItem = selectedCar.find((findElement) => findElement.parameterName === item.parameters);
      return { getSliderListArray: item, getSelectedCarArray: selectedItem };
    })
    .filter(
      ({ getSliderListArray, getSelectedCarArray }) =>
        getSliderListArray.data?.length > 0 && getSelectedCarArray?.text === 'no-value'
    )
    .map(({ getSliderListArray }) => getSliderListArray.parameters);

  if (
    errors.length > 0 &&
    !(
      initialData.FitAssistantVariation === FitAssistantVariation.RooftopTents &&
      !(
        errors.find((x) => x === FitAssistantStep.RoofRacks) &&
        errors.find((x) => x === FitAssistantStep.GenericRoofRacks)
      )
    ) &&
    !(
      initialData.FitAssistantVariation === FitAssistantVariation.RoofPlatforms &&
      errors.filter((x) => x === FitAssistantStep.RoofRacks)?.length > 0
    )
  ) {
    return errors;
  } else {
    return [];
  }
}
