import React from 'react';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepSelector,
  InitialDataSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { setFitStatus, setShowFitAssistant, setStepEnum } from '../../Slices/FitAssistantSlice';
import { trackOK } from '../../Analytics/Adobe';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import Buttons from '../Buttons/Buttons';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';

const RackNotCompatible = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const translations = useAppSelector(TranslationsSelector);

  const onClickPrimary = () => dispatch(setStepEnum(FitAssistantStep.RoofRacks));

  const onClickSecondary = () => {
    dispatch(setFitStatus(FitAssistantStatus.NoFit));
    dispatch(setShowFitAssistant(false));

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  return (
    <>
      <div>
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{
            __html: currentStepContent?.Text ?? '',
          }}
        />
        {currentStepContent?.ImageUrl && <img src={currentStepContent?.ImageUrl} alt={currentStepContent?.ImageAlt} />}
      </div>
      <Buttons visible>
        <PrimaryButton visible text={translations.buyRoofRack} onClick={onClickPrimary} />
        <SecondaryButton visible text={translations.close} onClick={onClickSecondary} />
      </Buttons>
    </>
  );
};

export default RackNotCompatible;
