import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../Store/store';

export const CarInformationSelector = (state: RootState) => state.FitAssistant.carInformation;
export const HasErrorSelector = (state: RootState) => state.FitAssistant.hasError;
export const HasLoadedFromCookieSelector = (state: RootState) => state.FitAssistant.hasLoadedFromCookie;
export const ConfirmedCompatibilitySelector = (state: RootState) => state.FitAssistant.confirmedCompatibility;
export const CurrentStepSelector = (state: RootState) => state.FitAssistant.step;
export const CurrentStepContentSelector = (state: RootState) =>
  state.FitAssistant.initialData.Steps.find((x) => x.Parameter === state.FitAssistant.step);
export const CurrentStepDataSelector = (state: RootState) =>
  state.FitAssistant.stepData.find((x) => x.parameters === state.FitAssistant.step);
export const FitStatusSelector = (state: RootState) => state.FitAssistant.fitStatus;
export const InitialDataSelector = (state: RootState) => state.FitAssistant.initialData;
export const ShowFitAssistantSelector = (state: RootState) => state.FitAssistant.showFitAssistant;
export const ShowMoreInfoSelector = (state: RootState) => state.FitAssistant.showMoreInfo;
export const ShowResetButtonsSelector = (state: RootState) => state.FitAssistant.showResetButtons;
export const StepContentListSelector = (state: RootState) => state.FitAssistant.initialData.Steps;
export const StepDataListSelector = (state: RootState) => state.FitAssistant.stepData;
export const TranslationsSelector = (state: RootState) => state.FitAssistant.initialData.Translations;
export const FooterIsFixedSelector = (state: RootState) => state.FitAssistant.footerIsFixed;
export const ShowFitGuideModalSelector = (state: RootState) => state.FitAssistant.showFitGuideModal;
export const ShowFitTipsModalSelector = (state: RootState) => state.FitAssistant.showFitTipsModal;

export const ProductDetailsSelector = (state: RootState) => state.ProductDetail;

export const StepHasProductSearchSelector = createSelector(
  (state) => state,
  (state: RootState) => {
    return (
      state.FitAssistant.initialData.Steps.filter(
        (step) => !step.SkipWhenLoadingFromCookie && step.Parameter === state.FitAssistant.step && step.DoProductSearch
      ).length > 0
    );
  }
);

export const StepHasSelectedOptionSelector = createSelector(
  (state) => state,
  (state: RootState) => {
    return (
      state.FitAssistant.stepData.filter(
        (x) => x.parameters === state.FitAssistant.step && x.data?.filter((x) => x.Selected).length > 0
      ).length > 0
    );
  }
);
