import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowResetButtonsSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { MatchingProductsSelector } from '../../Selectors/MatchingProductsSelectors';
import { setShowFitAssistant, setShowResetButtons } from '../../Slices/FitAssistantSlice';
import { resetFitAssistant } from '../../Thunks/Reset';
import { trackOK, trackView } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import SeatIndicator from './SeatIndicator';

import './CarSeatFit.less';

const CarSeatFit = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const matchingProducts = useAppSelector(MatchingProductsSelector);
  const showResetButton = useAppSelector(ShowResetButtonsSelector);
  const translations = useAppSelector(TranslationsSelector);

  const carSeatPositions = matchingProducts ? matchingProducts[0]?.CarSeatPositions : null;
  const isFiveSeat: boolean = carSeatPositions?.slice(-3) === 'XXX';

  useEffect(() => {
    trackView(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  }, []);

  const onClickPrimary = () => {
    dispatch(setShowFitAssistant(false));
    dispatch(setShowResetButtons(true));

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  const onClickSecondary = () => {
    dispatch(resetFitAssistant());
  };

  return (
    <>
      <div className="d-flex position-relative flex-column car-seats">
        <div className="car-wrapper mb-7">
          <div className="d-flex justify-content-center">
            <div
              className={`car-image-wrapper position-relative mt-6 ${isFiveSeat ? 'five-seat-car' : 'eight-seat-car'} ${initialData.IsRightHandDrive ? 'right-hand-drive' : ''}`}
            >
              <img
                src={isFiveSeat ? initialData.FiveSeatCarImageUrl! : initialData.EightSeatCarImageUrl!}
                alt={isFiveSeat ? initialData.FiveSeatCarImageAltText! : initialData.EightSeatCarImageAltText!}
                className="w-100"
              />
              {Array.from(Array(7).keys()).map((x) => (
                <SeatIndicator index={x + 1} isFit={carSeatPositions?.slice(x, x + 1) === 'Y'} key={x} />
              ))}
            </div>
          </div>
          <p className="paragraph--xs pb-3 ms-3 mb-0">{translations.seeDetailedFitInfoText}</p>
        </div>
        <div>
          <h5 className="heading5 mb-5">{translations.additionalFitInfo}</h5>
          <p className="mb-7">{translations.limitationText}</p>
          <h5 className="heading5 mb-5">{translations.legend}</h5>
          <div className="d-flex flex-column">
            <span className="d-flex align-items-center legend-item">
              <i className="le-icon-check-circle fit"></i>
              {translations.fitsSeat}
            </span>
            <span className="d-flex align-items-center legend-item">
              <i className="le-icon-x-circle no-fit"></i>
              {translations.doesNotFitText}
            </span>
          </div>
          <hr />
          <h5 className="heading5 mb-5">{translations.aboutCarFitHeader}</h5>
          <p className="mb-13" dangerouslySetInnerHTML={{ __html: currentStepContent?.Text ?? '' }}></p>
        </div>
      </div>

      <Buttons visible>
        <PrimaryButton visible text={translations.confirm} onClick={onClickPrimary} />
        <SecondaryButton visible={showResetButton} text={translations.reset} onClick={onClickSecondary} />
      </Buttons>
    </>
  );
};

export default CarSeatFit;
