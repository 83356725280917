import { OperationVariables, QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../../../../../../../Foundation/SitecoreGraphQL/code/clientv2';
import { getAdditionalFilterForOptions } from '../../FitAssistantShared/Functions';
import { getWindowData } from '../../FitAssistantShared/GetWindowData';
import { getPerCategorySearchString, getSearchString } from '../../FitAssistantShared/SearchString';
import { GET_OPTIONS_QUERY } from '../Queries/Queries';
import { RootState } from '../Store/store';
import { CarInformation, FitAssistantState, Step, StepData } from '../Types/FitAssistantData';
import { GetOptionsQuery } from '../Types/FitAssistantOptionsResponse';
import { FetchListOptionArgs, RejectValue } from './Types';
import { addCarInformation } from '../Slices/FitAssistantSlice';

export const fetchListOptions = createAsyncThunk<StepData, FetchListOptionArgs, RejectValue>(
  'fitAssistant/fetchListOptions',
  async ({ text, value, parameter }, { getState, dispatch, rejectWithValue }) => {
    let state = getState() as RootState;

    let updatedCarInformation: CarInformation[] = updateCarInfoList(text, value, parameter, state.FitAssistant);
    dispatch(addCarInformation(updatedCarInformation));

    const searchString: string = getSearchString(updatedCarInformation);
    const perCategorySelection = getPerCategorySearchString(updatedCarInformation);

    let [configurationItem, nextStep] = GetConfigurationData(updatedCarInformation, state.FitAssistant);

    const getOptionsVariables: OperationVariables = {
      siteName: getWindowData().SitecoreSiteName,
      language: getWindowData().SitecoreLanguage,
      configurationItem: configurationItem,
      parameters: `${searchString},${perCategorySelection}`,
      additionalFilter: getAdditionalFilterForOptions(nextStep, state.FitAssistant.initialData.AdditionalFilters),
    };

    const getOptions: QueryOptions = {
      query: GET_OPTIONS_QUERY,
      variables: getOptionsVariables,
    };

    let result = await client.query<GetOptionsQuery>(getOptions);

    if (result.error) return rejectWithValue(result.error.message);

    let stepToAdd: StepData = {
      data: result.data.getCarSelectorOptions.carSelectorOptionsResponse.Options,
      messages: result.data.getCarSelectorOptions.carSelectorOptionsResponse.OptionMessages,
      parameters: result.data.getCarSelectorOptions.carSelectorOptionsResponse.ParameterName,
      configItems: result.data.getCarSelectorOptions.carSelectorOptionsResponse.NextConfigurationItem,
    };

    if (stepToAdd.data.length === 0 && stepToAdd.configItems === null) {
      const lastCarInfoWithoutData: CarInformation[] = updateCarInfoList(
        'no-value',
        '0',
        stepToAdd.parameters,
        state.FitAssistant
      );

      dispatch(addCarInformation(lastCarInfoWithoutData));
    }

    return stepToAdd;
  }
);

const updateCarInfoList = (
  text: string,
  value: string,
  parameter: string,
  state: FitAssistantState
): CarInformation[] => {
  const getDuplicatesIndex = state.carInformation.findIndex((duplicate) => duplicate.parameterName === parameter);

  let newCarInformation: CarInformation[];

  if (getDuplicatesIndex >= 0) {
    newCarInformation = state.carInformation.map((item, i) =>
      i === getDuplicatesIndex
        ? {
            ...item,
            text,
            value,
            configurationItem:
              state.stepData[state.stepData.length - 1].configItems === null
                ? state.stepData[state.stepData.length - 2].configItems
                : item.configurationItem,
          }
        : item
    );
  } else {
    newCarInformation = state.carInformation.concat({
      parameterName: parameter,
      configurationItem:
        state.stepData[state.stepData.length - 1].configItems === null
          ? state.stepData[state.stepData.length - 2].configItems
          : state.stepData[state.stepData.length - 1].configItems,
      text,
      value,
    } as CarInformation);
  }
  const index = newCarInformation.findIndex((x: CarInformation) => x.parameterName === parameter);
  return newCarInformation.slice(0, index + 1);
};

const GetConfigurationData = (updatedCarInformation: CarInformation[], state: FitAssistantState) => {
  const lastNoneNullConfigurationItem = [...updatedCarInformation]
    .reverse()
    .find((item) => item.configurationItem !== null);
  const nextStep = state.initialData.Steps.find((x) => x.Id === lastNoneNullConfigurationItem?.configurationItem);

  const tuple: [string, Step] = [lastNoneNullConfigurationItem?.configurationItem!, nextStep!];

  return tuple;
};
