import React, { memo } from 'react';
import ThirdPartyRackOption from './ThirdPartyRackOption';
import { ContentOption } from '../../Types/FitAssistantData';

type ThirdPartyRackOptionsProps = {
  options: ContentOption[];
  onSelect: (option: ContentOption) => void;
  selected: ContentOption | undefined;
};

const ThirdPartyRackOptions = memo(({ options, onSelect, selected }: ThirdPartyRackOptionsProps) => (
  <div className="rack-options mt-6">
    {options.map((option, index) => (
      <ThirdPartyRackOption
        onClick={() => onSelect(option)}
        name={option.Title}
        description={option.Description}
        image={option.ImageUrl}
        alt={option.ImageAlt}
        isSelected={selected === option}
        key={index}
      />
    ))}
  </div>
));

export default ThirdPartyRackOptions;
