import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { Constants } from '../../Constants';
import { ImageLoadEvent } from '../../Types/Events';
import { resetFitAssistant } from '../../Thunks/Reset';
import {
  CarInformationSelector,
  CurrentStepDataSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowMoreInfoSelector,
  StepContentListSelector,
  StepDataListSelector,
  StepHasProductSearchSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { fetchListOptions } from '../../Thunks/FetchListOptions';
import { CarInformation, StepDataData } from '../../Types/FitAssistantData';
import { MatchingProductsSelector } from '../../Selectors/MatchingProductsSelectors';
import {
  addCarInformation,
  selectOption,
  setFooterIsFixed,
  setShowResetButtons,
  unselectOptions,
} from '../../Slices/FitAssistantSlice';
import { getProducts } from '../../Thunks/Queries';
import { ProductSearchType } from '../../Enums/ProductSearchType';
import { FooterHelper } from '../../Helpers/MoreInfoHelper';
import { trackChooseVariation, trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import SecondaryButton from '../Buttons/SecondaryButton';
import VariationOption from './VariationOption';
import FitAssistantStep from '../../Enums/FitAssistantStep';

import './VariationSelector.less';
import PrimaryButtonVariation from '../Buttons/PrimaryButtonVariation';

const VariationSelector = () => {
  const [totalImageHeight, setTotalImageHeight] = useState<number>(0);

  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepData = useAppSelector(CurrentStepDataSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const matchingProducts = useAppSelector(MatchingProductsSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const stepContent = useAppSelector(StepContentListSelector);
  const stepDataList = useAppSelector(StepDataListSelector);
  const stepHasProductSearch = useAppSelector(StepHasProductSearchSelector);
  const translations = useAppSelector(TranslationsSelector);

  useEffect(() => {
    document.addEventListener(Constants.Events.VARIATION_OPTION_IMAGE_LOAD, updateTotalImageHeight as EventListener);

    return () => {
      document.removeEventListener(
        Constants.Events.VARIATION_OPTION_IMAGE_LOAD,
        updateTotalImageHeight as EventListener
      );
    };
  }, []);

  useEffect(() => {
    let imageResult = FooterHelper.FooterShouldBeFixed('.variation-selector');
    dispatch(setFooterIsFixed(imageResult));
  }, [totalImageHeight, stepDataList]);

  const updateTotalImageHeight = (e: CustomEvent<ImageLoadEvent>): void => {
    setTotalImageHeight((current) => current + e.detail.height);
  };

  const getCarInformation = (option: StepDataData | undefined): CarInformation[] => {
    const filteredCarinformation = carInformation.filter((x) => x.parameterName != currentStep);
    const configItem = currentStepData?.configItems;

    if (!option) return filteredCarinformation;

    const variation: CarInformation = {
      parameterName: currentStep,
      configurationItem: configItem ?? '',
      text: option.EnglishText ?? option.Text ?? '',
      value: option.Value,
    };

    let updCarInformation: CarInformation[] = [...filteredCarinformation, variation];
    return updCarInformation;
  };

  const handlePrimaryButtonClick = (): void => {
    let updatedCarInformation: CarInformation[] = [];

    if (!stepHasProductSearch) {
      dispatch(
        fetchListOptions({
          text: currentStepData?.data?.find((x) => x.Selected)?.Text ?? '',
          value: currentStepData?.data?.find((x) => x.Selected)?.Value ?? '',
          parameter: stepDataList[stepDataList.length - 1].parameters,
        })
      );
    } else {
      updatedCarInformation = setCompatibility();
    }

    trackOK(
      initialData.FitAssistantVariation,
      currentStep,
      initialData.IsYearFirst,
      updatedCarInformation ?? carInformation
    );
  };

  const setCompatibility = (): CarInformation[] => {
    const selectedOption = currentStepData?.data?.find((x) => x.Selected);
    const selectedValues = getCarInformation(selectedOption);

    dispatch(addCarInformation(selectedValues));

    dispatch(getProducts(ProductSearchType.VariationSelector));

    return selectedValues;
  };

  const handleOptionClick = (option: StepDataData, index: number): void => {
    if (!option.Selected) {
      let carInfo = getCarInformation(option);
      trackChooseVariation(initialData.FitAssistantVariation, initialData.IsYearFirst, carInfo);
      dispatch(addCarInformation(carInfo));
      dispatch(selectOption(index));
    } else {
      let carInfoWithoutVariation = carInformation.filter((x) => x.parameterName != currentStep);
      dispatch(addCarInformation(carInfoWithoutVariation));
      dispatch(unselectOptions());
    }
  };

  return (
    <>
      {!showMoreInfo && (
        <>
          <div className="variation-selector d-flex flex-column justify-content-center">
            <div className="variation-options">
              {currentStepData?.data?.map((option, index) => {
                return <VariationOption onClick={() => handleOptionClick(option, index)} option={option} key={index} />;
              })}
              {currentStepData?.data.length === 0 && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: stepContent.find((x) => x.Parameter === FitAssistantStep.NoFits)?.Text ?? '',
                  }}
                ></span>
              )}
            </div>
          </div>
          <Buttons visible={!showMoreInfo}>
            <PrimaryButtonVariation text={translations.confirm} onClick={handlePrimaryButtonClick} />
            <SecondaryButton
              visible={(matchingProducts !== null && matchingProducts.length > 0) ?? false}
              text={translations.reset}
              onClick={() => dispatch(resetFitAssistant())}
            />
          </Buttons>
        </>
      )}
    </>
  );
};

export default VariationSelector;
