import React from 'react';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import {
  CurrentStepSelector,
  InitialDataSelector,
  StepContentListSelector,
} from '../../Selectors/FitAssistantSelectors';
import { useAppSelector } from '../../Store/store';

import './ProgressBar.less';

const ProgressBar = () => {
  const currentStep = useAppSelector(CurrentStepSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const stepContentList = useAppSelector(StepContentListSelector);

  const getProgressBarStep = (): number | null => {
    switch (currentStep) {
      case FitAssistantStep.Make:
        return initialData.IsYearFirst ? 2 : 1;
      case FitAssistantStep.Model:
        return initialData.IsYearFirst ? 3 : 2;
      case FitAssistantStep.Year:
        return initialData.IsYearFirst ? 1 : 3;
      case FitAssistantStep.Variation:
        return 4;
      case FitAssistantStep.VanSize:
      case FitAssistantStep.GenericRoofRacks:
      case FitAssistantStep.CarSeatFit:
        return 5;
      default:
        return null;
    }
  };

  const getNumberOfProgressBarSteps = (): number => {
    return stepContentList.filter(
      (x) =>
        x.Parameter === FitAssistantStep.Make ||
        x.Parameter === FitAssistantStep.Model ||
        x.Parameter === FitAssistantStep.Year ||
        x.Parameter === FitAssistantStep.Variation ||
        x.Parameter === FitAssistantStep.VanSize ||
        x.Parameter === FitAssistantStep.GenericRoofRacks ||
        x.Parameter === FitAssistantStep.CarSeatFit
    ).length;
  };

  const step = getProgressBarStep();
  const steps = getNumberOfProgressBarSteps();

  return (
    <div className={`progress-bar mt-5 ${!step ? 'd-none' : ''}`}>
      <div className="line w-100"></div>
      {step && <div className="line fill" style={{ width: `${(step / steps) * 100}%` }}></div>}
    </div>
  );
};

export default ProgressBar;
