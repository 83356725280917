import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepDataSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowMoreInfoSelector,
  ShowResetButtonsSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { addCarInformation, selectOption, setFooterIsFixed, setShowResetButtons } from '../../Slices/FitAssistantSlice';
import { CookieHelper } from '../../Helpers/CookieHelper';
import { CarInformation, StepDataData } from '../../Types/FitAssistantData';
import { getPerCategorySearchString, getSearchString } from '../../../FitAssistantShared/SearchString';
import { resetFitAssistant } from '../../Thunks/Reset';
import { getOptions, getProducts } from '../../Thunks/Queries';
import { ProductSearchType } from '../../Enums/ProductSearchType';
import { FooterHelper } from '../../Helpers/MoreInfoHelper';
import { trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import RackOptionItem from './RackOptionItem';

import './RackSelector.less';

const RooftopTentsRackSelector = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepData = useAppSelector(CurrentStepDataSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const showResetButton = useAppSelector(ShowResetButtonsSelector);
  const translations = useAppSelector(TranslationsSelector);

  useEffect(() => {
    if (
      !currentStepData?.data ||
      currentStepData?.data?.length === 0 ||
      !currentStepData?.data?.find((x) => x.Selected)
    ) {
      dispatch(getOptions());
    } else {
      dispatch(getProducts(ProductSearchType.RooftopTentsRackSelector));
    }
  }, []);

  useEffect(() => {
    let result = FooterHelper.FooterShouldBeFixed('.rack-selector');
    dispatch(setFooterIsFixed(result));

    return () => {
      dispatch(setFooterIsFixed(false));
    };
  }, [currentStepData]);

  const getCarInformation = (option: StepDataData): CarInformation[] => {
    const configItem = currentStepData?.configItems;
    const variation: CarInformation = {
      parameterName: currentStep,
      configurationItem: configItem ?? '',
      text: option.Text,
      value: option.Value,
    };

    let filteredCarinformation = carInformation.filter((x) => x.parameterName != currentStep);
    let updCarInformation: CarInformation[] = [...filteredCarinformation, variation];
    return updCarInformation;
  };

  const onClickPrimary = async (): Promise<void> => {
    const selection: string = getSearchString(carInformation);
    const perCategorySelection: string = getPerCategorySearchString(carInformation);

    CookieHelper.SetCookies(selection, perCategorySelection);

    dispatch(getProducts(ProductSearchType.RooftopTentsRackSelector));

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  const onClickSecondary = (): void => {
    dispatch(resetFitAssistant());
  };

  const handleOptionClick = (option: StepDataData, index: number): void => {
    if (!option.Selected) {
      dispatch(selectOption(index));
      dispatch(setShowResetButtons(false));
      dispatch(addCarInformation(getCarInformation(option)));
    }
  };

  return (
    <>
      {!showMoreInfo && (
        <>
          <div className="rack-selector d-flex flex-column justify-content-center">
            <div
              className="info-text"
              dangerouslySetInnerHTML={{
                __html: currentStepContent?.Text ?? '',
              }}
            />
            <h5>{translations.selectRoofRack}</h5>
            <div className="rack-options mt-6">
              {currentStepData?.data?.map((option, index) => {
                return <RackOptionItem key={index} option={option} onClick={() => handleOptionClick(option, index)} />;
              })}
            </div>
          </div>
          <Buttons visible={currentStepData?.data?.some((x) => x.Selected) ?? false}>
            <PrimaryButton visible text={translations.confirm} onClick={onClickPrimary} />
            <SecondaryButton visible={showResetButton} text={translations.reset} onClick={onClickSecondary} />
          </Buttons>
        </>
      )}
    </>
  );
};

export default RooftopTentsRackSelector;
