import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CarInformation, FitAssistantState, StepData } from '../Types/FitAssistantData';
import { resetFitAssistant } from '../Thunks/Reset';
import { getOptions } from '../Thunks/Queries';
import { fetchListOptions } from '../Thunks/FetchListOptions';
import FitAssistantStatus from '../Enums/FitAssistantStatus';
import FitAssistantStep from '../Enums/FitAssistantStep';
import { loadOptionsFromCookie } from '../Thunks/LoadOptionsFromCookie';
import { trackView } from '../Analytics/Adobe';
import FitAssistantVariation from '../Enums/FitAssistantVariation';

const initialData = { ...window._THULEDATA.FitAssistant };

const initialState: FitAssistantState = {
  carInformation: [],
  fitStatus: FitAssistantStatus.NoSelection,
  initialData: initialData,
  step: initialData.Steps[0].Parameter,
  stepData: [],
  confirmedCompatibility: false,
  hasError: false,
  showFitAssistant: false,
  showFitGuideModal: false,
  showFitTipsModal: false,
  showMoreInfo: false,
  showResetButtons: false,
  footerIsFixed: false,
  hasLoadedFromCookie: false,
};

export const FitAssistantSlice = createSlice({
  name: 'fitAssistant',
  initialState,
  reducers: {
    goBack: (state) => {
      if (state.stepData.find((x) => x.parameters === state.step)) {
        let isRTTRoofRacks =
          state.initialData.FitAssistantVariation === FitAssistantVariation.RooftopTents &&
          state.step === FitAssistantStep.RoofRacks;
        let numberToRemoveFromStepData = isRTTRoofRacks ? -2 : -1;
        let numberToRemoveFromCarInformation =
          isRTTRoofRacks &&
          state.carInformation.find((x) => x.parameterName === FitAssistantStep.RoofRacks) &&
          state.carInformation.find((x) => x.parameterName === FitAssistantStep.GenericRoofRacks)
            ? -2
            : -1;

        let removeLastListItem = state.stepData.slice(0, numberToRemoveFromStepData);
        let removeLastCarInformation = state.carInformation.slice(0, numberToRemoveFromCarInformation);
        let removeAllAfterModel = removeLastListItem.length;
        var previousStep =
          removeLastListItem.length - 1 >= 0
            ? removeLastListItem[removeLastListItem.length - 1].parameters
            : state.initialData.Steps[0].Parameter;

        if (removeAllAfterModel >= 0) {
          state.stepData = state.stepData.slice(0, removeAllAfterModel);
          state.carInformation = removeLastCarInformation.slice(0, removeAllAfterModel);
          state.step = previousStep;
          state.showResetButtons = false;
          state.confirmedCompatibility = false;
        } else {
          if (removeLastListItem.length === 1) {
            removeLastCarInformation = [];
          }

          state.stepData = removeLastListItem;
          state.carInformation = removeLastCarInformation;
          state.step = previousStep;
          state.showResetButtons = false;
          state.confirmedCompatibility = false;
        }
        trackViewOnStepChange(state, previousStep);
      } else {
        let newStep =
          state.stepData.length > 0
            ? state.stepData[state.stepData.length - 1].parameters
            : state.initialData.Steps[0].Parameter;

        state.step = newStep;
        state.showResetButtons = false;
        state.confirmedCompatibility = false;

        trackViewOnStepChange(state, newStep);
      }
    },
    setShowFitAssistant: (state, { payload }: PayloadAction<boolean>) => {
      state.showFitAssistant = payload;
      if (payload) trackViewOnVisibility(state, state.step);
    },
    setShowMoreInfo: (state, { payload }: PayloadAction<boolean>) => {
      state.showMoreInfo = payload;
    },
    setFitStatus: (state, { payload }: PayloadAction<FitAssistantStatus>) => {
      state.fitStatus = payload;
    },
    setShowResetButtons: (state, { payload }: PayloadAction<boolean>) => {
      state.showResetButtons = payload;
    },
    setShowFitGuideModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showFitGuideModal = payload;
    },
    setShowFitTipsModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showFitTipsModal = payload;
    },
    addCarInformation: (state, { payload }: PayloadAction<CarInformation[]>) => {
      state.carInformation = payload;
    },
    setStepEnum: (state, { payload }: PayloadAction<FitAssistantStep>) => {
      trackViewOnStepChange(state, payload);

      state.step = payload;
    },
    setConfirmedCompatibility: (state, { payload }: PayloadAction<boolean>) => {
      state.confirmedCompatibility = payload;
    },
    setHasError: (state, { payload }: PayloadAction<boolean>) => {
      state.hasError = payload;
    },
    setFooterIsFixed: (state, { payload }: PayloadAction<boolean>) => {
      state.footerIsFixed = payload;
    },
    resetContent: (state) => {
      state.step = state.initialData.Steps[0].Parameter;
      state.stepData = [];
      state.carInformation = [];
      state.showResetButtons = false;
      state.confirmedCompatibility = false;
    },
    addSelectedCarFromCookie: (state, { payload }) => {
      state.stepData = payload.getSliderList;
      state.carInformation = payload.getSelectedCar;
      state.step = payload.step;
      state.showResetButtons = true;
    },
    addStepToList: (state, { payload }: PayloadAction<StepData>) => {
      const getDuplicatesIndex = state.stepData.findIndex((data) => data.parameters === payload.parameters);

      if (getDuplicatesIndex !== -1) {
        let updatedStepData = state.stepData.map((item, i) => (i === getDuplicatesIndex ? payload : item));
        let index = state.stepData?.findIndex((x) => x.parameters === payload.parameters);

        if (index) {
          updatedStepData = updatedStepData.slice(0, index + 1);
          state.step = payload.parameters;
        }

        state.stepData = updatedStepData;
      } else {
        state.stepData = [...state.stepData, payload];
      }
    },
    selectOption: (state, { payload }: PayloadAction<number>) => {
      let data = state.stepData
        .find((x) => x.parameters === state.step)
        ?.data.map((option, index) =>
          index === payload ? { ...option, Selected: true } : { ...option, Selected: false }
        );
      let updatedStepData = state.stepData.map((stepData) =>
        stepData.parameters === state.step ? { ...stepData, data: data } : stepData
      );

      state.stepData = updatedStepData as StepData[];
    },
    unselectOptions: (state) => {
      let data = state.stepData
        .find((x) => x.parameters === state.step)
        ?.data.map((option) => {
          return { ...option, Selected: false };
        });
      let updatedStepData = state.stepData.map((stepData) =>
        stepData.parameters === state.step ? { ...stepData, data: data } : stepData
      );

      state.stepData = updatedStepData as StepData[];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFitAssistant.fulfilled, (state) => {
      state.fitStatus = FitAssistantStatus.NoSelection;
      FitAssistantSlice.caseReducers.resetContent(state);
    });
    builder.addCase(loadOptionsFromCookie.fulfilled, (state) => {
      state.hasLoadedFromCookie = true;
    });
    builder.addMatcher(
      isAnyOf(getOptions.fulfilled, fetchListOptions.fulfilled),
      (state, { payload }: PayloadAction<StepData>) => {
        let stepToListpayload = { payload } as PayloadAction<StepData>;
        let stepEnumPayload = { payload: stepToListpayload.payload.parameters } as PayloadAction<FitAssistantStep>;
        FitAssistantSlice.caseReducers.addStepToList(state, stepToListpayload);
        FitAssistantSlice.caseReducers.setStepEnum(state, stepEnumPayload);
      }
    );
  },
});

const trackViewOnStepChange = (state: FitAssistantState, step: FitAssistantStep) => {
  if (
    step === FitAssistantStep.Make ||
    step === FitAssistantStep.Model ||
    step === FitAssistantStep.Year ||
    (step === FitAssistantStep.Variation && state.step !== FitAssistantStep.Variation)
  ) {
    trackView(state.initialData.FitAssistantVariation, step, state.initialData.IsYearFirst, state.carInformation);
  }
};

const trackViewOnVisibility = (state: FitAssistantState, step: FitAssistantStep) => {
  let shortVersions = [
    FitAssistantStep.Make,
    FitAssistantStep.Model,
    FitAssistantStep.Year,
    FitAssistantStep.Variation,
  ];

  if (state.stepData.length > 0 && shortVersions.includes(step))
    trackView(state.initialData.FitAssistantVariation, step, state.initialData.IsYearFirst, state.carInformation);
};

export const {
  addCarInformation,
  addSelectedCarFromCookie,
  addStepToList,
  goBack,
  resetContent,
  selectOption,
  setConfirmedCompatibility,
  setFitStatus,
  setHasError,
  setFooterIsFixed,
  setShowFitAssistant,
  setShowFitGuideModal,
  setShowFitTipsModal,
  setShowMoreInfo,
  setShowResetButtons,
  setStepEnum,
  unselectOptions,
} = FitAssistantSlice.actions;

export default FitAssistantSlice.reducer;
