import { createAsyncThunk } from '@reduxjs/toolkit';
import { OperationVariables, QueryOptions } from '@apollo/client';
import { client } from '../../../../../../../../../Foundation/SitecoreGraphQL/code/clientv2';
import { RootState } from '../Store/store';
import { getPerCategorySearchString, getSearchString } from '../../FitAssistantShared/SearchString';
import { getWindowData } from '../../FitAssistantShared/GetWindowData';
import { GET_OPTIONS_QUERY, GET_PRODUCT_QUERY } from '../Queries/Queries';
import { getAdditionalFilterForOptions } from '../../FitAssistantShared/Functions';
import { GetOptionsQuery } from '../Types/FitAssistantOptionsResponse';
import { GetProductsQuery } from '../Types/FitAssistantProductsResponse';
import { StepData } from '../Types/FitAssistantData';
import { RejectValue } from './Types';
import { ProductLoader } from '../Helpers/ProductLoader';
import { IsProductPage } from '../../FitAssistantShared/IsProductPage';
import { ProductSearchType } from '../Enums/ProductSearchType';
import { ProductPageHelper } from '../Helpers/ProductPageHelper';

export const getOptions = createAsyncThunk<StepData, void, RejectValue>(
  'fitAssistant/getOptions',
  async (_, { getState, rejectWithValue }) => {
    let state = getState() as RootState;
    const searchString = getSearchString(state.FitAssistant.carInformation);
    const currentStepData = state.FitAssistant.initialData.Steps.find((x) => x.Parameter === state.FitAssistant.step);

    const getOptionsVariables: OperationVariables = {
      siteName: getWindowData().SitecoreSiteName,
      language: getWindowData().SitecoreLanguage,
      configurationItem: currentStepData?.Id,
      parameters: searchString,
      additionalFilter: getAdditionalFilterForOptions(
        currentStepData,
        state.FitAssistant.initialData.AdditionalFilters
      ),
    };

    const getOptions: QueryOptions = {
      fetchPolicy: 'network-only',
      query: GET_OPTIONS_QUERY,
      variables: getOptionsVariables,
    };

    let result = await client.query<GetOptionsQuery>(getOptions);

    if (result.error) return rejectWithValue(result.error.message);

    let stepToAdd: StepData = {
      data: result.data.getCarSelectorOptions.carSelectorOptionsResponse.Options,
      messages: result.data.getCarSelectorOptions.carSelectorOptionsResponse.OptionMessages,
      parameters: result.data.getCarSelectorOptions.carSelectorOptionsResponse.ParameterName,
      configItems: result.data.getCarSelectorOptions.carSelectorOptionsResponse.NextConfigurationItem,
    };

    return stepToAdd;
  }
);

export const getProducts = createAsyncThunk<GetProductsQuery, ProductSearchType, RejectValue>(
  'fitAssistant/getProducts',
  async (productSearchType, { getState, dispatch, rejectWithValue }) => {
    let state = getState() as RootState;

    let carInformation = state.FitAssistant.carInformation;
    let currentStepContent = state.FitAssistant.initialData.Steps.find((x) => x.Parameter === state.FitAssistant.step);
    let currentStepData = state.FitAssistant.stepData.find((x) => x.parameters === state.FitAssistant.step);
    let parameters = `${getSearchString(carInformation)},${getPerCategorySearchString(carInformation)}`;

    const getOptionsVariables: OperationVariables = {
      siteName: getWindowData().SitecoreSiteName,
      language: getWindowData().SitecoreLanguage,
      configurationItem: currentStepContent?.Id,
      selectedproductpimid: state.ProductDetail.ProductId || '',
      parameters: parameters,
      additionalFilter: getAdditionalFilterForOptions(
        currentStepContent,
        state.FitAssistant.initialData.AdditionalFilters
      ),
      ispreview: state.FitAssistant.initialData.IsPreview,
    };

    const getOptions: QueryOptions = {
      fetchPolicy: 'network-only',
      query: GET_PRODUCT_QUERY,
      variables: getOptionsVariables,
    };

    let result = await client.query<GetProductsQuery>(getOptions);

    if (result.error) return rejectWithValue(result.error.message);

    let productLoader = new ProductLoader(
      result.data,
      state.FitAssistant.carInformation,
      null,
      true,
      state.FitAssistant.initialData,
      state.FitAssistant.stepData,
      dispatch
    );

    switch (productSearchType) {
      case ProductSearchType.SelectList:
        productLoader.HandleSelectListClick();
        break;
      case ProductSearchType.GenericRackSelector:
      case ProductSearchType.VariationSelector:
        productLoader.Load();
        break;
      default:
        productLoader.Load();

        if (IsProductPage) {
          ProductPageHelper.HandleProductPageData(
            currentStepData!,
            state.ProductDetail,
            state.FitAssistant.initialData,
            carInformation,
            dispatch
          );
        }
    }

    return result.data;
  }
);
