import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import FitAssistant from './FitAssistant';
import store from './Store/store';

const rootElement = document.getElementById('REACT_FitAssistant_new');

if (rootElement) {
  let root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <FitAssistant />
    </Provider>
  );
}
