import React, { Fragment, useEffect } from 'react';
import { FitTip } from '../../Types/MatchingProducts';
import { FitTipsSelector } from '../../Selectors/MatchingProductsSelectors';
import { ShowFitTipsModalSelector } from '../../Selectors/FitAssistantSelectors';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { setShowFitTipsModal } from '../../Slices/FitAssistantSlice';

const FitTipsModal = () => {
  const html: HTMLHtmlElement = document.querySelector('html')!;
  const body: HTMLBodyElement = document.querySelector('body')!;

  const dispatch = useAppDispatch();
  const showFitTipModal = useAppSelector(ShowFitTipsModalSelector);
  const fitTips = useAppSelector(FitTipsSelector);

  useEffect(() => {
    if (showFitTipModal) {
      html.classList.add('overflow-hidden');
      body.classList.add('overflow-hidden');
    } else {
      html.classList.remove('overflow-hidden');
      body.classList.remove('overflow-hidden');
    }
  }, [showFitTipModal]);

  return (
    <div className="my-3 fit-guide">
      <div
        onClick={() => dispatch(setShowFitTipsModal(!showFitTipModal))}
        className={`fit-guide-left-slide-in-menu-backdrop backdrop ${showFitTipModal ? 'active' : ''}`}
      ></div>
      <div className={`fit-guide-menu left-slide-in-menu-wrapper  ${showFitTipModal ? '' : 'wrapper-hidden'}`}>
        <section className="menu-content-section d-inline-block">
          <div className="menu-buttons slide-in-menu-heading justify-content-end">
            <button
              type="button"
              className="top-left-mini-btn hide-fit-guide-overlay d-flex fit-guide-button p-0"
              onClick={() => dispatch(setShowFitTipsModal(false))}
            >
              <i className="le-icon-x"></i>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {fitTips.map((fitTip: FitTip, index: number) => {
            return (
              <Fragment key={index}>
                <p className="heading4 mb-5">{fitTip.FitTipTitle}</p>
                <p className="paragraph--s mb-7 text-color--gray80">{fitTip.FitTipDescription}</p>
              </Fragment>
            );
          })}
        </section>
      </div>
    </div>
  );
};

export default FitTipsModal;
