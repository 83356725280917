import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ButtonsContainerProps } from './Types';

import './Buttons.less';

const Buttons = ({ children, visible = false }: ButtonsContainerProps) => {
  const portal = document.querySelector('#buttonPortal');

  const [isDOMReady, setIsDOMReady] = useState<boolean>(false);
  useEffect(() => {
    setIsDOMReady(true);
  }, []);

  return isDOMReady && portal && visible && React.Children.count(children) > 0
    ? createPortal(<div className="confirmation">{children}</div>, portal)
    : null;
};

export default Buttons;
