enum FitAssistantVariation {
  None,
  Awnings,
  CarRoofRacks,
  CarSeats,
  DogCrates,
  RoofPlatforms,
  RooftopTents,
  TruckBedBikeRacks,
  TruckRackSystem,
  TruckAndSpareTireBikeRacks,
  VanRackSystems,
}

export default FitAssistantVariation;
