import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { Constants } from '../../Constants';
import { ImageLoadEvent } from '../../Types/Events';
import { resetFitAssistant } from '../../Thunks/Reset';
import {
  CarInformationSelector,
  CurrentStepDataSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowMoreInfoSelector,
  StepContentListSelector,
  StepDataListSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { CarInformation, StepDataData } from '../../Types/FitAssistantData';
import { MatchingProductsSelector } from '../../Selectors/MatchingProductsSelectors';
import {
  addCarInformation,
  selectOption,
  setFitStatus,
  setFooterIsFixed,
  setShowResetButtons,
  setStepEnum,
  unselectOptions,
} from '../../Slices/FitAssistantSlice';
import { SetFitStatusForAllProducts, setQueryFromSearchParameters } from '../../../FitAssistantShared/Functions';
import { getPerCategorySearchString, getSearchString } from '../../../FitAssistantShared/SearchString';
import { CookieHelper } from '../../Helpers/CookieHelper';
import { IsProductPage } from '../../../FitAssistantShared/IsProductPage';
import { FooterHelper } from '../../Helpers/MoreInfoHelper';
import { trackChooseVariation, trackFit, trackNoFit, trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import FitAssistantRooftopTentStatus from '../../Enums/FitAssistantRooftopTentStatus';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import PrimaryButtonVariation from '../Buttons/PrimaryButtonVariation';
import SecondaryButton from '../Buttons/SecondaryButton';
import VariationOption from './VariationOption';

import './VariationSelector.less';

const VariationSelector = () => {
  const [totalImageHeight, setTotalImageHeight] = useState<number>(0);

  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepData = useAppSelector(CurrentStepDataSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const matchingProducts = useAppSelector(MatchingProductsSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const stepContent = useAppSelector(StepContentListSelector);
  const stepDataList = useAppSelector(StepDataListSelector);
  const translations = useAppSelector(TranslationsSelector);

  useEffect(() => {
    document.addEventListener(Constants.Events.VARIATION_OPTION_IMAGE_LOAD, updateTotalImageHeight as EventListener);

    return () => {
      document.removeEventListener(
        Constants.Events.VARIATION_OPTION_IMAGE_LOAD,
        updateTotalImageHeight as EventListener
      );
    };
  }, []);

  useEffect(() => {
    let imageResult = FooterHelper.FooterShouldBeFixed('.roof-type-selector');
    dispatch(setFooterIsFixed(imageResult));

    return () => {
      dispatch(setFooterIsFixed(false));
    };
  }, [totalImageHeight, stepDataList]);

  const updateTotalImageHeight = (e: CustomEvent<ImageLoadEvent>): void => {
    setTotalImageHeight((current) => current + e.detail.height);
  };

  const getCarInformation = (option: StepDataData | undefined): CarInformation[] => {
    const filteredCarinformation = carInformation.filter((x) => x.parameterName != currentStep);
    const configItem = currentStepData?.configItems;

    if (!option || !configItem) return filteredCarinformation;

    const variation: CarInformation = {
      parameterName: currentStep,
      configurationItem: configItem ?? '',
      text: option.EnglishText ?? option.Text ?? '',
      value: option.Value,
    };

    let updCarInformation: CarInformation[] = [...filteredCarinformation, variation];
    return updCarInformation;
  };

  const setCompatibility = (): void => {
    const selectedOption = currentStepData?.data?.find((x) => x.Selected);
    const selectedValues = getCarInformation(selectedOption);

    dispatch(addCarInformation(selectedValues));

    let status = currentStepData?.data?.find((x) => x.Selected)?.RooftopTentStatus;
    switch (status) {
      case FitAssistantRooftopTentStatus.Confirmed:
        dispatch(setStepEnum(FitAssistantStep.GenericRoofRacks));
        trackFit(initialData.FitAssistantVariation, initialData.IsYearFirst, carInformation);
        break;
      case FitAssistantRooftopTentStatus.NoFit:
        SetFitStatusForAllProducts(FitAssistantStatus.NoFit);
        dispatch(setFitStatus(FitAssistantStatus.NoFit));
        dispatch(setStepEnum(FitAssistantStep.NoFits));
        dispatch(setShowResetButtons(true));
        break;
      case FitAssistantRooftopTentStatus.Unconfirmed:
        SetFitStatusForAllProducts(FitAssistantStatus.NoFit);
        dispatch(setFitStatus(FitAssistantStatus.NoFit));
        dispatch(setStepEnum(FitAssistantStep.Unconfirmed));
        break;
    }

    const selection: string = getSearchString(carInformation);
    const perCategorySelection: string = getPerCategorySearchString(carInformation);

    CookieHelper.SetCookies(selection, perCategorySelection);

    if (IsProductPage) {
      setQueryFromSearchParameters(initialData.FitAssistantCookieName, carInformation);
    }

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, selectedValues);
  };

  const handleOptionClick = (option: StepDataData, index: number): void => {
    if (!option.Selected) {
      let carInfoForTracking = getCarInformation(option);
      trackChooseVariation(initialData.FitAssistantVariation, initialData.IsYearFirst, carInfoForTracking);
      dispatch(selectOption(index));
    } else {
      dispatch(unselectOptions());
    }
  };

  return (
    <>
      {!showMoreInfo && (
        <>
          <div className="roof-type-selector d-flex flex-column justify-content-center">
            <div className="variation-options">
              {currentStepData?.data?.map((option, index) => {
                return <VariationOption onClick={() => handleOptionClick(option, index)} option={option} key={index} />;
              })}
              {currentStepData?.data.length === 0 && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: stepContent.find((x) => x.Parameter === FitAssistantStep.NoFits)?.Text ?? '',
                  }}
                ></span>
              )}
            </div>
          </div>
          <Buttons visible={!showMoreInfo}>
            <PrimaryButtonVariation text={translations.confirm} onClick={setCompatibility} />
            <SecondaryButton
              visible={(matchingProducts !== null && matchingProducts.length > 0) ?? false}
              text={translations.reset}
              onClick={() => dispatch(resetFitAssistant())}
            />
          </Buttons>
        </>
      )}
    </>
  );
};

export default VariationSelector;
