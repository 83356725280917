import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import {
  CarInformationSelector,
  CurrentStepSelector,
  HasLoadedFromCookieSelector,
  InitialDataSelector,
  ShowMoreInfoSelector,
  ShowResetButtonsSelector,
  StepDataListSelector,
  StepHasProductSearchSelector,
  StepHasSelectedOptionSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { fetchListOptions } from '../../Thunks/FetchListOptions';
import { getOptions, getProducts } from '../../Thunks/Queries';
import { resetFitAssistant } from '../../Thunks/Reset';
import { CarInformation, StepDataData } from '../../Types/FitAssistantData';
import { addCarInformation, selectOption, setFooterIsFixed } from '../../Slices/FitAssistantSlice';
import { FooterHelper } from '../../Helpers/MoreInfoHelper';
import { ProductSearchType } from '../../Enums/ProductSearchType';
import { trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import SelectListItemForProductSearch from './SelectListItemForProductSearch';
import SelectListItem from './SelectListItem';

import './SelectList.less';

const SelectList = () => {
  const [query, setQuery] = useState('');

  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const hasLoadedFromCookie = useAppSelector(HasLoadedFromCookieSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const showResetbutton = useAppSelector(ShowResetButtonsSelector);
  const stepDataList = useAppSelector(StepDataListSelector);
  const stepHasProductSearch = useAppSelector(StepHasProductSearchSelector);
  const stepHasSelectedOption = useAppSelector(StepHasSelectedOptionSelector);
  const translations = useAppSelector(TranslationsSelector);

  useEffect(() => {
    setQuery('');

    if (!hasLoadedFromCookie || stepDataList.length > 0) return;
    dispatch(getOptions());
  }, [stepDataList, hasLoadedFromCookie]);

  useEffect(() => {
    let result = FooterHelper.FooterShouldBeFixed('.select-list ');
    dispatch(setFooterIsFixed(result));
  }, [stepDataList]);

  const getCarInformation = (option: StepDataData): CarInformation[] => {
    const configItem = stepDataList?.find((x) => x.parameters === currentStep)?.configItems;
    const year = {
      parameterName: currentStep,
      configurationItem: configItem,
      text: option.Text,
      value: option.Value,
    };
    let updCarInformation = [...carInformation.filter((x) => x.parameterName != currentStep), year] as CarInformation[];
    return updCarInformation;
  };

  const generateOptionItems = () => {
    return stepDataList
      .find((x) => x.parameters === currentStep)
      ?.data?.filter((x) => x.Text.toLowerCase().includes(query.toLowerCase()))
      .map((item, index) => {
        return stepHasProductSearch ? (
          <SelectListItemForProductSearch
            item={item}
            key={index}
            isSelected={item.Selected}
            onSelect={() => onSelect(item, index)}
          />
        ) : (
          <SelectListItem item={item} onClick={onClickSelectListItem} key={index} />
        );
      });
  };

  const onSelect = (item: StepDataData, index: number): void => {
    dispatch(selectOption(index));
    dispatch(addCarInformation(getCarInformation(item)));
  };

  const onClickSelectListItem = (item: StepDataData): void => {
    dispatch(
      fetchListOptions({
        text: item.Text,
        value: item.Value,
        parameter: stepDataList[stepDataList.length - 1].parameters,
      })
    );
  };

  const onClickPrimary = () => {
    dispatch(getProducts(ProductSearchType.SelectList));
    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  const onClickSecondary = () => {
    dispatch(resetFitAssistant());
  };

  return (
    <>
      {!showMoreInfo && (
        <div className="select-list d-flex flex-column justify-content-center">
          <div className={`search-bar ${currentStep === FitAssistantStep.Year ? 'd-none' : ''}`}>
            <input
              type="text"
              placeholder={translations.search}
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <i className="le-icon-search"></i>
          </div>
          <div className="list-wrapper">{generateOptionItems()}</div>
        </div>
      )}

      <Buttons visible={stepHasProductSearch && stepHasSelectedOption && !showMoreInfo}>
        <PrimaryButton visible text={translations.confirm} onClick={onClickPrimary} />
        <SecondaryButton visible={showResetbutton} text={translations.reset} onClick={onClickSecondary} />
      </Buttons>
    </>
  );
};

export default SelectList;
