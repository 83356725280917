import React from 'react';
import {
  CurrentStepSelector,
  StepContentListSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { setShowMoreInfo } from '../../Slices/FitAssistantSlice';
import Buttons from '../Buttons/Buttons';
import PrimaryButton from '../Buttons/PrimaryButton';

const Information = () => {
  const dispatch = useAppDispatch();
  const fitAssistantStepContent = useAppSelector(StepContentListSelector);
  const fitAssistantStep = useAppSelector(CurrentStepSelector);
  const translations = useAppSelector(TranslationsSelector);

  return (
    <>
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: fitAssistantStepContent.find((x) => x.Parameter === fitAssistantStep)?.MoreInfoContent?.Text || '',
          }}
        />
      </div>
      <Buttons visible>
        <PrimaryButton visible text={translations.back} onClick={() => dispatch(setShowMoreInfo(false))} />
      </Buttons>
    </>
  );
};

export default Information;
