import React, { ReactEventHandler } from 'react';
import { Constants } from '../../Constants';
import { ImageLoadEvent } from '../../Types/Events';
import { StepDataData } from '../../Types/FitAssistantData';

type VariationOptionProps = {
  option: StepDataData;
  onClick: () => void;
};

const RoofTypeOption = ({ option, onClick }: VariationOptionProps) => {
  const handleImageLoad: ReactEventHandler<HTMLImageElement> = (event) => {
    document.dispatchEvent(
      new CustomEvent<ImageLoadEvent>(Constants.Events.VARIATION_OPTION_IMAGE_LOAD, {
        detail: { height: (event?.target as HTMLImageElement)?.clientHeight },
      })
    );
  };

  return (
    <div
      className={`variation-option px-4 py-4 ${option.Selected ? 'selected' : ''} ${option.HasSolutions ? '' : 'disabled'}`}
      onClick={onClick}
    >
      <div className="image-wrapper d-flex justify-content-center w-100 mb-3">
        {option.Image && <img src={option.Image} alt={option.AltText!} onLoad={handleImageLoad} />}
      </div>
      <h5 className="mb-2">{option.Text}</h5>
      {option.Description && <p>{option.Description}</p>}
    </div>
  );
};

export default RoofTypeOption;
