import FitAssistantStatus from '../Enums/FitAssistantStatus';
import { CarInformation, Translations } from '../Types/FitAssistantData';

export class ButtonHelper {
  public static GetSelectionText(
    fitStatus: FitAssistantStatus,
    carInformation: CarInformation[],
    translations: Translations
  ): string {
    if (!translations) return '';

    switch (fitStatus) {
      case FitAssistantStatus.NoSelection:
        return translations.confirmVehicleFit;
      case FitAssistantStatus.Confirmed:
      case FitAssistantStatus.Unconfirmed:
      case FitAssistantStatus.NoFit:
        return this.GetCarInformationBtnText(carInformation);
      case FitAssistantStatus.RackMounting:
        return translations.rackMounting;
      default:
        return '';
    }
  }

  public static GetStatusLabel(fitStatus: FitAssistantStatus, translations: Translations): string {
    let label = '';
    switch (fitStatus) {
      case FitAssistantStatus.NoSelection:
        label = '';
        break;
      case FitAssistantStatus.Confirmed:
      case FitAssistantStatus.RackMounting:
        label = translations.confirmed;
        break;
      case FitAssistantStatus.Unconfirmed:
        label = translations.unconfirmed;
        break;
      case FitAssistantStatus.NoFit:
        label = translations.noFit;
        break;
    }
    return label;
  }

  private static GetCarInformationBtnText(carInformation: CarInformation[]): string {
    if (carInformation && carInformation.length >= 3)
      return carInformation[0].text + ' ' + carInformation[1].text + ' ' + carInformation[2].text;
    return '';
  }
}
