import React from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { FooterIsFixedSelector, TranslationsSelector } from '../../Selectors/FitAssistantSelectors';
import { setShowMoreInfo } from '../../Slices/FitAssistantSlice';

import './Footer.less';

const Footer = () => {
  const dispatch = useAppDispatch();
  const footerIsFixed = useAppSelector(FooterIsFixedSelector);
  const translations = useAppSelector(TranslationsSelector);

  return (
    <div className={`footer-link ${footerIsFixed ? 'fixed' : ''}`}>
      <a className="w-100 text-center" onClick={() => dispatch(setShowMoreInfo(true))}>
        {translations && translations.moreInfo}
      </a>
    </div>
  );
};

export default Footer;
