import { Cookies } from 'typescript-cookie';
import { getWindowData } from '../../FitAssistantShared/GetWindowData';

export type Cookie = {
  cookie: string;
  perCategoryCookie: string;
};

export type CookieNames = {
  cookieName: string;
  perCategoryCookieName: string;
};

export class CookieHelper {
  public static GetCookies(): Cookie {
    let cookieNames = this.GetCookieNames();

    return {
      cookie: Cookies.get(cookieNames.cookieName) as string,
      perCategoryCookie: Cookies.get(cookieNames.perCategoryCookieName) as string,
    };
  }
  public static SetCookies(selection: string, perCategorySelection: string): void {
    let cookieNames = this.GetCookieNames();

    Cookies.set(cookieNames.cookieName, selection, {
      expires: 365,
      path: `/${getWindowData().SitecoreLanguage.toLowerCase()}/`,
      secure: true,
      sameSite: 'strict',
    });

    Cookies.set(cookieNames.perCategoryCookieName, perCategorySelection, {
      expires: 365,
      path: window.location.pathname
        .split('/')
        .filter((x) => !x.includes('-_-'))
        .join('/'),
      secure: true,
      sameSite: 'strict',
    });
  }
  public static RemoveCookies(): void {
    let cookieNames = this.GetCookieNames();

    Cookies.remove(cookieNames.cookieName, {
      path: `/${getWindowData().SitecoreLanguage.toLowerCase()}/`,
    });

    Cookies.remove(cookieNames.perCategoryCookieName, {
      path: window.location.pathname
        .split('/')
        .filter((x) => !x.includes('-_-'))
        .join('/'),
    });
  }

  public static GetCookieNames(): CookieNames {
    return {
      cookieName: window._THULEDATA.FitAssistant.FitAssistantCookieName,
      perCategoryCookieName: window._THULEDATA.FitAssistant.FitAssistantPerCategoryCookieName,
    };
  }
}
