import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MatchingProductsState, SolutionComponent } from '../Types/MatchingProducts';
import { resetFitAssistant } from '../Thunks/Reset';
import { GetProductsQuery } from '../Types/FitAssistantProductsResponse';

const initialState: MatchingProductsState = {
  matchingProducts: null,
  solutionComponents: null,
  productPageMatch: false,
};

export const MatchingProductsSlice = createSlice({
  name: 'matchingProducts',
  initialState,
  reducers: {
    addMatchingProducts: (state, { payload }: PayloadAction<GetProductsQuery>) => {
      state.matchingProducts = payload?.getCarSelectorProducts?.carSelectorProductsResponse?.MatchingProducts;
      state.solutionComponents = payload?.getCarSelectorProducts?.carSelectorProductsResponse?.SolutionComponents;
      state.productPageMatch = payload?.getCarSelectorProducts?.carSelectorProductsResponse?.ProductPageMatch;
    },
    addSolutionComponents: (state, { payload }: PayloadAction<SolutionComponent[]>) => {
      state.solutionComponents = payload;
    },
    clearMatchingProducts: (state) => {
      state.matchingProducts = null;
      state.solutionComponents = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFitAssistant.fulfilled, (state) => {
      MatchingProductsSlice.caseReducers.clearMatchingProducts(state);
    });
  },
});

export const { addMatchingProducts, addSolutionComponents, clearMatchingProducts } = MatchingProductsSlice.actions;

export default MatchingProductsSlice.reducer;
