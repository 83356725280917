import FitAssistantStep from '../Enums/FitAssistantStep';
import { CarInformation } from '../Types/FitAssistantData';

declare global {
  interface Window {
    google: any;
  }
}

export const onFitAssistantOpen = (carInformation: CarInformation[]) => {
  if (typeof google !== 'undefined')
    window.google.tagmanager.add({
      event: 'FA_Open',
      ...getGTMCarInformation(carInformation),
    });
};

export const onFitAssistantSaveData = (carInformation: CarInformation[]) => {
  if (typeof google !== 'undefined')
    window.google.tagmanager.add({
      event: 'FA_Save',
      ...getGTMCarInformation(carInformation),
    });
};

const getGTMCarInformation = (carInformation: CarInformation[]) => {
  const brand = carInformation.find((x: CarInformation) => x.parameterName === FitAssistantStep.Make)?.text;
  const year = carInformation.find((x: CarInformation) => x.parameterName === FitAssistantStep.Year)?.text;
  const model = carInformation.find((x: CarInformation) => x.parameterName === FitAssistantStep.Model)?.text;
  const variation = carInformation.find((x: CarInformation) => x.parameterName === FitAssistantStep.Variation)?.text;

  return {
    brand: brand,
    year: year,
    model: model,
    variation: variation,
    selectedFilter: `${brand} ${model} ${year} | ${variation}`,
    url: window.location.href?.split('?')[0],
  };
};
