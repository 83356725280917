import React from 'react';
import { StepDataData } from '../../Types/FitAssistantData';

type SelectListItem = {
  item: StepDataData;
  onClick: (item: StepDataData) => void;
};

const SelectListItem = ({ item, onClick }: SelectListItem) => {
  return (
    <div className="list-item" onClick={() => onClick(item)}>
      <div className="list-item-text">
        <span className="header">{item.Text}</span>
        <i className="le-icon-chevron rot-180"></i>
      </div>
    </div>
  );
};

export default SelectListItem;
