import React from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { ButtonHelper } from '../../Helpers/ButtonHelper';
import { setShowFitAssistant, setShowFitGuideModal, setShowFitTipsModal } from '../../Slices/FitAssistantSlice';
import { IsProductPage } from '../../../FitAssistantShared/IsProductPage';
import { getIconClassByFitAssistantStatus } from '../../../FitAssistantShared/Functions';
import { onFitAssistantOpen } from '../../Analytics/Google';
import {
  CarInformationSelector,
  CurrentStepSelector,
  FitStatusSelector,
  InitialDataSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { FitTipsSelector, SolutionComponentsSelector } from '../../Selectors/MatchingProductsSelectors';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import FitTipsModal from '../Modal/FitTipsModal';
import FitGuideModal from '../Modal/FitGuideModal';

import './FitAssistantButton.less';
import { trackOpen, trackOpenEmpty } from '../../Analytics/Adobe';
import FitAssistantVariation from '../../Enums/FitAssistantVariation';

const FitAssistantButton = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const fitStatus = useAppSelector(FitStatusSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const translations = useAppSelector(TranslationsSelector);
  const solutionComponents = useAppSelector(SolutionComponentsSelector);
  const fitTips = useAppSelector(FitTipsSelector);

  const handleButtonClick = () => {
    onFitAssistantOpen(carInformation);

    let shortVersions = [
      FitAssistantVariation.CarSeats,
      FitAssistantVariation.DogCrates,
      FitAssistantVariation.TruckBedBikeRacks,
      FitAssistantVariation.TruckAndSpareTireBikeRacks,
    ];

    let hasCarVariation = carInformation.find((x) => x.parameterName === FitAssistantStep.Variation);
    let yearFirstHasModel =
      initialData.IsYearFirst && carInformation.find((x) => x.parameterName === FitAssistantStep.Model);
    let brandFirstHasYear =
      !initialData.IsYearFirst && carInformation.find((x) => x.parameterName === FitAssistantStep.Year);

    if (
      (shortVersions.includes(initialData.FitAssistantVariation) && (yearFirstHasModel || brandFirstHasYear)) ||
      hasCarVariation
    ) {
      trackOpen(initialData.FitAssistantVariation, initialData.IsYearFirst, currentStep, carInformation);
    } else if (fitStatus !== FitAssistantStatus.RackMounting) {
      trackOpenEmpty(initialData.FitAssistantVariation, initialData.IsYearFirst, carInformation, currentStep);
    }

    dispatch(setShowFitAssistant(true));
  };

  const renderSolutionComponents = () => {
    return solutionComponents?.map((item, index) => {
      if (item.Name.includes('Kit')) {
        return <li key={index}>{item.Name}</li>;
      }
      return (
        <li key={index}>
          {item.Name} {item.Webtitle2} | {item.ProductId}
        </li>
      );
    });
  };

  return (
    <>
      <div className="fit-assistant">
        <div className="header-wrapper mb-5">
          <h5 className={`heading5 mb-0 ${!IsProductPage ? 'd-none' : ''}`}>
            {translations && translations.fit}
            {fitStatus !== FitAssistantStatus.NoSelection && (
              <span className="fit-status">{ButtonHelper.GetStatusLabel(fitStatus, translations)}</span>
            )}
          </h5>
          {initialData.FitGuide?.LinkText && IsProductPage && (
            <a href="#" onClick={() => dispatch(setShowFitGuideModal(true))}>
              {initialData.FitGuide.LinkText}
            </a>
          )}
        </div>
        <div role="button" className={`fit-assistant-button d-flex`} onClick={handleButtonClick} tabIndex={0}>
          <i className={`fit-indicator ${fitStatus} ${getIconClassByFitAssistantStatus(fitStatus)}`}></i>
          <span className={`heading6 text-color--black fit-guide-span ${fitStatus} has-indicator ms-3`}>
            {ButtonHelper.GetSelectionText(fitStatus, carInformation, translations)}
          </span>
        </div>
        {IsProductPage && currentStep === FitAssistantStep.OtherFits && (
          <div className="my-3">
            <span className="text-color--gray80">{translations.otherFitsText}</span>
            <a href={initialData.ParentPageLink} className="text-decoration-underline text-color--gray80">
              {translations.exploreAlternatives}
            </a>
          </div>
        )}
        {currentStep === FitAssistantStep.NoFits && (
          <div className="my-3">
            <span className="text-color--gray80">{translations.noFitsText}</span>
          </div>
        )}
        {currentStep === FitAssistantStep.Unconfirmed && (
          <div className="my-3">
            <span className="text-color--gray80">{translations.unconfirmedText}</span>
          </div>
        )}
        {fitTips.length > 0 && IsProductPage && (
          <div onClick={() => dispatch(setShowFitTipsModal(true))} className="fit-tip-box p-5 mt-5 d-flex">
            <i className="le-icon-info me-3"></i>
            <div className="d-flex flex-column">
              <span className="mb-1">{translations.vehicleFitTipsTitle}</span>
              <span className="action-text">{translations.learnMore}</span>
            </div>
          </div>
        )}
        {solutionComponents != null && solutionComponents.length > 0 && (
          <div className="my-5 text-color--gray80">
            <span>{translations.includedToFit}</span>
            <ul>{renderSolutionComponents()}</ul>
          </div>
        )}
      </div>
      {initialData.FitGuide?.LinkText && IsProductPage && <FitGuideModal />}
      {fitTips.length > 0 && IsProductPage && <FitTipsModal />}
    </>
  );
};

export default FitAssistantButton;
