import React, { useEffect } from 'react';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowResetButtonsSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { resetFitAssistant } from '../../Thunks/Reset';
import { setShowFitAssistant, setShowResetButtons } from '../../Slices/FitAssistantSlice';
import { trackNoFit, trackView } from '../../Analytics/Adobe';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import Buttons from '../Buttons/Buttons';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const NoFit = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const showResetButtons = useAppSelector(ShowResetButtonsSelector);
  const translations = useAppSelector(TranslationsSelector);

  useEffect(() => {
    trackNoFit(initialData.FitAssistantVariation, initialData.IsYearFirst, carInformation);
    trackView(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  }, []);

  const onClickPrimary = () => {
    dispatch(setShowFitAssistant(false));
    dispatch(setShowResetButtons(true));
  };

  const onClickSecondary = () => dispatch(resetFitAssistant());

  return (
    <>
      <div>
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{
            __html: currentStepContent?.Text ?? '',
          }}
        />
        {currentStepContent?.ImageUrl && <img src={currentStepContent?.ImageUrl} alt={currentStepContent?.ImageAlt} />}
      </div>
      <Buttons visible>
        <PrimaryButton visible text={translations.close} onClick={onClickPrimary} />
        <SecondaryButton visible={showResetButtons} text={translations.reset} onClick={onClickSecondary} />
      </Buttons>
    </>
  );
};

export default NoFit;
