import React from 'react';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepSelector,
  FitStatusSelector,
  InitialDataSelector,
  ProductDetailsSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { resetFitAssistant } from '../../Thunks/Reset';
import { setFitStatus, setShowFitAssistant } from '../../Slices/FitAssistantSlice';
import { IsProductPage } from '../../../FitAssistantShared/IsProductPage';
import { SetFitStatusForAllProducts } from '../../../FitAssistantShared/Functions';
import { CookieHelper } from '../../Helpers/CookieHelper';
import { ProductPageHelper } from '../../Helpers/ProductPageHelper';
import { trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const RackMountingSelector = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const fitStatus = useAppSelector(FitStatusSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const productDetail = useAppSelector(ProductDetailsSelector);
  const translations = useAppSelector(TranslationsSelector);

  const onClickPrimary = () => {
    dispatch(setFitStatus(FitAssistantStatus.RackMounting));
    if (IsProductPage) {
      ProductPageHelper.HandleRackMountingProductPageData(productDetail, initialData, dispatch);
    } else {
      SetFitStatusForAllProducts(FitAssistantStatus.Confirmed);
    }
    CookieHelper.SetCookies('', FitAssistantStatus.RackMounting);
    dispatch(setShowFitAssistant(false));

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  return (
    <>
      <div>
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{
            __html: currentStepContent?.Text ?? '',
          }}
        />
        {currentStepContent?.ImageUrl && <img src={currentStepContent?.ImageUrl} alt={currentStepContent?.ImageAlt} />}
      </div>
      <Buttons visible>
        <PrimaryButton visible text={translations.confirm} onClick={onClickPrimary} />
        <SecondaryButton
          visible={fitStatus === FitAssistantStatus.RackMounting}
          text={translations.reset}
          onClick={() => dispatch(resetFitAssistant())}
        />
      </Buttons>
    </>
  );
};

export default RackMountingSelector;
