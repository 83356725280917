import React from 'react';

type ThirdPartyRackOptionProps = {
  image: string;
  alt: string;
  name: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
};

const ThirdPartyRackOption = ({ image, alt, name, description, isSelected, onClick }: ThirdPartyRackOptionProps) => {
  return (
    <div className={`rack-option d-flex mb-4 px-1 py-1 ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <div className="image-wrapper d-flex justify-content-center w-100">
        <img src={image} alt={alt}></img>
      </div>
      <div className="info d-flex flex-column justify-content-center">
        <h6 className="mb-1">{name}</h6>
        <p className={`${!description ? 'd-none' : ''}`}>{description}</p>
      </div>
    </div>
  );
};

export default ThirdPartyRackOption;
