import { UnknownAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { CarInformation, InitialData, StepData, StepDataData } from '../Types/FitAssistantData';
import { FitAssistantProductsResponse, GetProductsQuery } from '../Types/FitAssistantProductsResponse';
import { SetFitStatusForAllProducts, setCompabilityVisually } from '../../FitAssistantShared/Functions';
import { setFitStatus, setShowFitAssistant, setShowResetButtons, setStepEnum } from '../Slices/FitAssistantSlice';
import { addMatchingProducts } from '../Slices/MatchingProductsSlice';
import { Constants } from '../Constants';
import { IsProductPage } from '../../FitAssistantShared/IsProductPage';
import { SelectedCarFromCookie } from '../Thunks/Types';
import FitAssistantStatus from '../Enums/FitAssistantStatus';
import FitAssistantStep from '../Enums/FitAssistantStep';
import FitAssistantRooftopTentStatus from '../Enums/FitAssistantRooftopTentStatus';
import FitAssistantVariation from '../Enums/FitAssistantVariation';
import { trackFit } from '../Analytics/Adobe';

export class ProductLoader {
  data: GetProductsQuery;
  selectedCar: CarInformation[];
  selectedCarFromCookie: SelectedCarFromCookie | null;
  setCookie: boolean;
  initialData: InitialData;
  stepData: StepData[];
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>;
  response: FitAssistantProductsResponse;

  constructor(
    data: GetProductsQuery,
    selectedCar: CarInformation[],
    selectedCarFromCookie: SelectedCarFromCookie | null,
    setCookie: boolean,
    initialData: InitialData,
    stepData: StepData[],
    dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
  ) {
    this.data = data;
    this.selectedCar = selectedCar;
    this.selectedCarFromCookie = selectedCarFromCookie;
    this.setCookie = setCookie;
    this.initialData = initialData;
    this.stepData = stepData;
    this.dispatch = dispatch;

    this.response = data.getCarSelectorProducts.carSelectorProductsResponse;
  }

  public Load(): void {
    if (this.data && this.response) {
      if (this.initialData.FitAssistantVariation === FitAssistantVariation.RoofPlatforms) {
        this.HandlePlatform();
      } else if (this.initialData.FitAssistantVariation === FitAssistantVariation.RooftopTents) {
        this.HandleRooftopTents();
      } else if (this.initialData.FitAssistantVariation === FitAssistantVariation.CarSeats) {
        this.HandleCarSeats();
      } else {
        this.HandleDefault();
      }
    }
  }

  private HandlePlatform(): void {
    let platformProducts = this.response.MatchingProducts?.filter(
      (x) => x.ObjectTypeId === Constants.SolutionTypes.PLATFORM
    );

    if (platformProducts?.length > 0) {
      const fitStatus = setCompabilityVisually(
        this.response,
        this.selectedCar,
        this.initialData,
        this.setCookie
      ) as FitAssistantStatus;

      if (fitStatus === FitAssistantStatus.Confirmed) {
        let stepsNotIncludingRoofRack = this.selectedCar.filter(
          (car) => car.parameterName !== FitAssistantStep.RoofRacks
        );
        let stepToShow = stepsNotIncludingRoofRack[stepsNotIncludingRoofRack.length - 1].parameterName;
        this.dispatch(setStepEnum(stepToShow));
        this.dispatch(setShowFitAssistant(false));

        trackFit(this.initialData.FitAssistantVariation, this.initialData.IsYearFirst, this.selectedCar);
      } else {
        this.dispatch(setStepEnum(FitAssistantStep.Unconfirmed));
      }

      this.dispatch(addMatchingProducts(this.data));
      this.dispatch(setFitStatus(fitStatus));
      this.dispatch(setShowResetButtons(true));
    } else if (
      this.response.MatchingProducts?.length > 0 &&
      this.response.MatchingProducts?.filter((x) => x.CompatibleBarForCaprock)?.length > 0
    ) {
      this.dispatch(setShowResetButtons(false));
      this.dispatch(setStepEnum(FitAssistantStep.RoofRacks));
    } else {
      const fitStatus = setCompabilityVisually(
        this.response,
        this.selectedCar,
        this.initialData,
        this.setCookie
      ) as FitAssistantStatus;
      this.dispatch(addMatchingProducts(this.data));
      this.dispatch(setFitStatus(fitStatus));
      this.dispatch(setStepEnum(FitAssistantStep.NoFits));
    }
  }

  private HandleRooftopTents(): void {
    let selectedOption: StepDataData | undefined;

    let steps = this.selectedCar.filter(
      (x) => x.value !== '0' && x.parameterName !== Constants.Parameters.CONFIRM_COMPATIBILITY
    );
    let currentStep = steps[steps.length - 1];

    if (this.selectedCarFromCookie) {
      selectedOption = this.selectedCarFromCookie.getSliderList
        .find((x) => x.parameters === currentStep.parameterName)
        ?.data?.find((x) => x.Selected);
    } else {
      selectedOption = this.stepData
        .find((x) => x.parameters === currentStep.parameterName)
        ?.data?.find((x) => x.Selected);
    }

    if (
      selectedOption?.CompatibleRackForTent === FitAssistantRooftopTentStatus.Confirmed &&
      (this.selectedCar.find((x) => x.parameterName === Constants.Parameters.CONFIRM_COMPATIBILITY) ||
        !selectedOption?.StructurallySupported ||
        (selectedOption?.StructurallySupported as FitAssistantRooftopTentStatus) ===
          FitAssistantRooftopTentStatus.Confirmed)
    ) {
      const fitStatus = setCompabilityVisually(
        this.response,
        this.selectedCar,
        this.initialData,
        this.setCookie
      ) as FitAssistantStatus;

      this.dispatch(addMatchingProducts(this.data));
      this.dispatch(setFitStatus(fitStatus));

      if (fitStatus === FitAssistantStatus.NoFit) {
        this.dispatch(setStepEnum(FitAssistantStep.NoFits));
        this.dispatch(setShowResetButtons(true));
      } else if (fitStatus === FitAssistantStatus.Confirmed) {
        let stepsWithValue = this.selectedCar.filter(
          (x) => x.value !== '0' && x.parameterName !== Constants.Parameters.CONFIRM_COMPATIBILITY
        );
        let stepToShow = stepsWithValue[stepsWithValue.length - 1].parameterName;

        this.dispatch(setStepEnum(stepToShow));
        this.dispatch(setShowFitAssistant(false));
        this.dispatch(setShowResetButtons(true));

        trackFit(this.initialData.FitAssistantVariation, this.initialData.IsYearFirst, this.selectedCar);
      } else {
        this.dispatch(setStepEnum(FitAssistantStep.Unconfirmed));
      }
    } else {
      this.dispatch(setStepEnum(FitAssistantStep.RackNotCompatible));
      this.dispatch(setFitStatus(FitAssistantStatus.NoFit));
      SetFitStatusForAllProducts(FitAssistantStatus.NoFit);
    }
  }

  private HandleCarSeats(): void {
    const fitStatus = setCompabilityVisually(
      this.response,
      this.selectedCar,
      this.initialData,
      this.setCookie
    ) as FitAssistantStatus;

    this.dispatch(addMatchingProducts(this.data));
    this.dispatch(setFitStatus(fitStatus));

    if (fitStatus === FitAssistantStatus.NoFit) {
      if (this.response.MatchingProducts?.length > 0) {
        if (IsProductPage) {
          this.dispatch(setStepEnum(FitAssistantStep.OtherFits));
        } else {
          this.dispatch(setStepEnum(FitAssistantStep.NoFits));
        }
      } else {
        this.dispatch(setStepEnum(FitAssistantStep.NoFits));
      }
    }

    if (fitStatus === FitAssistantStatus.Unconfirmed) {
      this.dispatch(setStepEnum(FitAssistantStep.Unconfirmed));
    }

    if (fitStatus === FitAssistantStatus.Confirmed) {
      this.dispatch(setStepEnum(FitAssistantStep.CarSeatFit));
      this.dispatch(setShowFitAssistant(false));

      trackFit(this.initialData.FitAssistantVariation, this.initialData.IsYearFirst, this.selectedCar);
    }
    this.dispatch(setShowResetButtons(true));
  }

  private HandleDefault(): void {
    const fitStatus = setCompabilityVisually(
      this.response,
      this.selectedCar,
      this.initialData,
      this.setCookie
    ) as FitAssistantStatus;

    this.dispatch(addMatchingProducts(this.data));
    this.dispatch(setFitStatus(fitStatus));

    if (fitStatus === FitAssistantStatus.NoFit) {
      if (this.response.MatchingProducts?.length > 0) {
        if (IsProductPage) {
          this.dispatch(setStepEnum(FitAssistantStep.OtherFits));
        } else {
          this.dispatch(setStepEnum(FitAssistantStep.NoFits));
        }
      } else {
        this.dispatch(setStepEnum(FitAssistantStep.NoFits));
      }
    }

    if (fitStatus === FitAssistantStatus.Unconfirmed) {
      this.dispatch(setStepEnum(FitAssistantStep.Unconfirmed));
    }

    if (fitStatus === FitAssistantStatus.Confirmed) {
      let stepsWithValue = this.selectedCar.filter((x) => x.value !== '0');
      let stepToShow = stepsWithValue[stepsWithValue.length - 1].parameterName;

      this.dispatch(setStepEnum(stepToShow));
      this.dispatch(setShowFitAssistant(false));

      trackFit(this.initialData.FitAssistantVariation, this.initialData.IsYearFirst, this.selectedCar);
    }

    this.dispatch(setShowResetButtons(true));
  }

  public HandleSelectListClick(): void {
    const fitStatus = setCompabilityVisually(
      this.response,
      this.selectedCar,
      this.initialData,
      this.setCookie
    ) as FitAssistantStatus;

    this.dispatch(addMatchingProducts(this.data));
    this.dispatch(setFitStatus(fitStatus));

    if (fitStatus === FitAssistantStatus.NoFit) {
      if (this.response.MatchingProducts?.length > 0) {
        if (IsProductPage) {
          this.dispatch(setStepEnum(FitAssistantStep.OtherFits));
        } else {
          this.dispatch(setStepEnum(FitAssistantStep.NoFits));
          this.dispatch(setShowResetButtons(true));
        }
      } else {
        this.dispatch(setStepEnum(FitAssistantStep.NoFits));
        this.dispatch(setShowResetButtons(true));
      }
    }

    if (fitStatus === FitAssistantStatus.Unconfirmed) {
      this.dispatch(setStepEnum(FitAssistantStep.Unconfirmed));
    }

    if (fitStatus === FitAssistantStatus.Confirmed) {
      if (this.initialData.FitAssistantVariation === FitAssistantVariation.CarSeats) {
        this.dispatch(setStepEnum(FitAssistantStep.CarSeatFit));
      } else {
        this.dispatch(setShowFitAssistant(false));
        this.dispatch(setShowResetButtons(true));
      }
      trackFit(this.initialData.FitAssistantVariation, this.initialData.IsYearFirst, this.selectedCar);
    }
  }
}
