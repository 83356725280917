import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { InitialData } from '../Types/FitAssistantData';
import { ProductDetail } from '../Types/ProductDetail';
import FitAssistantSlice from '../Slices/FitAssistantSlice';
import MatchingProductsSlice from '../Slices/MatchingProductsSlice';
import ProductDetailSlice from '../Slices/ProductDetailSlice';

declare global {
  interface Window {
    _THULEDATA: {
      SitecoreLanguage: string;
      SitecoreSiteName: string;
      FitAssistant: InitialData;
      ProductDetail: ProductDetail;
    };
  }
}

const store = configureStore({
  reducer: {
    FitAssistant: FitAssistantSlice,
    MatchingProducts: MatchingProductsSlice,
    ProductDetail: ProductDetailSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export default store;
