import React from 'react';
import { CurrentStepContentSelector, TranslationsSelector } from '../../Selectors/FitAssistantSelectors';
import { resetFitAssistant } from '../../Thunks/Reset';
import { setFitStatus, setShowFitAssistant } from '../../Slices/FitAssistantSlice';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import Buttons from '../Buttons/Buttons';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const NoRackFit = () => {
  const dispatch = useAppDispatch();
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const translations = useAppSelector(TranslationsSelector);

  const onClick = () => {
    dispatch(setFitStatus(FitAssistantStatus.NoFit));
    dispatch(setShowFitAssistant(false));
  };

  const onClickSecondary = () => {
    resetFitAssistant();
  };

  return (
    <>
      <div>
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{
            __html: currentStepContent?.Text ?? '',
          }}
        />
        {currentStepContent?.ImageUrl && <img src={currentStepContent?.ImageUrl} alt={currentStepContent?.ImageAlt} />}
      </div>
      <Buttons visible>
        <PrimaryButton visible text={translations.iUnderstand} onClick={onClick} />
        <SecondaryButton visible text={translations.reset} onClick={onClickSecondary} />
      </Buttons>
    </>
  );
};

export default NoRackFit;
