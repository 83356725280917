import { ThunkDispatch } from 'redux-thunk';
import { UpdateFitAssistantPriceAndATC, setQueryFromSearchParameters } from '../../FitAssistantShared/Functions';
import { addSolutionComponents } from '../Slices/MatchingProductsSlice';
import { CarInformation, InitialData, StepData } from '../Types/FitAssistantData';
import { SolutionComponent } from '../Types/MatchingProducts';
import { ProductDetail } from '../Types/ProductDetail';
import { UnknownAction } from 'redux';

export class ProductPageHelper {
  public static HandleProductPageData(
    currentStepData: StepData | undefined,
    productDetail: ProductDetail,
    initialData: InitialData,
    carInformation: CarInformation[],
    dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
  ): void {
    let selectedRack = currentStepData?.data.find((x) => x.Selected === true);

    if (!selectedRack) return;

    let productIds = selectedRack?.SolutionProducts?.map((x) => x.ProductId);
    let currentProductId = productDetail.ProductId;
    let name = productDetail.ProductName;
    let webtitle2 = productDetail.WebTitle2;
    let currentSolutionComponent = {
      ProductId: currentProductId,
      Name: name,
      Webtitle2: webtitle2,
    };
    let adapterId = initialData?.RackMountingAdapter?.ProductId;
    let adapterName = initialData?.RackMountingAdapter?.Name;
    let adapterWebtitle2 = initialData?.RackMountingAdapter?.Webtitle2;
    let adapterComponent = {
      ProductId: adapterId,
      Name: adapterName,
      Webtitle2: adapterWebtitle2,
    };
    let productIdsForATC = adapterId ? [...productIds, currentProductId, adapterId] : [...productIds, currentProductId];
    UpdateFitAssistantPriceAndATC(productIdsForATC);

    let componentList = adapterId
      ? ([currentSolutionComponent, ...selectedRack.SolutionProducts, adapterComponent] as SolutionComponent[])
      : ([currentSolutionComponent, ...selectedRack.SolutionProducts] as SolutionComponent[]);

    dispatch(addSolutionComponents(componentList));

    setQueryFromSearchParameters(initialData.FitAssistantCookieName, carInformation);
  }

  public static HandleRackMountingProductPageData(
    productDetail: ProductDetail,
    initialData: InitialData,
    dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
  ) {
    let currentProductId = productDetail.ProductId;
    let name = productDetail.ProductName;
    let webtitle2 = productDetail.WebTitle2;
    let currentSolutionComponent = {
      ProductId: currentProductId,
      Name: name,
      Webtitle2: webtitle2,
    };
    let adapterId = initialData?.RackMountingAdapter?.ProductId;
    let adapterName = initialData?.RackMountingAdapter?.Name;
    let adapterWebtitle2 = initialData?.RackMountingAdapter?.Webtitle2;
    let adapterComponent = {
      ProductId: adapterId,
      Name: adapterName,
      Webtitle2: adapterWebtitle2,
    };

    let productIdsForATC = adapterId ? [currentProductId, adapterId] : [currentProductId];
    UpdateFitAssistantPriceAndATC(productIdsForATC);

    if (adapterId) dispatch(addSolutionComponents([currentSolutionComponent, adapterComponent] as SolutionComponent[]));
  }
}
