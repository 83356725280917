import React from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import {
  CarInformationSelector,
  CurrentStepSelector,
  FitStatusSelector,
  HasErrorSelector,
  InitialDataSelector,
  ShowMoreInfoSelector,
  StepContentListSelector,
  StepDataListSelector,
} from '../../Selectors/FitAssistantSelectors';
import { MatchingProductsSelector } from '../../Selectors/MatchingProductsSelectors';
import { ResetFitStatusForAllProducts } from '../../../FitAssistantShared/Functions';
import {
  setFitStatus,
  setShowResetButtons,
  setShowFitAssistant,
  setShowMoreInfo,
  setHasError,
  goBack,
} from '../../Slices/FitAssistantSlice';
import { clearMatchingProducts } from '../../Slices/MatchingProductsSlice';
import { trackClose } from '../../Analytics/Adobe';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import ProgressBar from '../ProgressBar/ProgressBar';

import './Header.less';

const Header = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const fitStatus = useAppSelector(FitStatusSelector);
  const hasError = useAppSelector(HasErrorSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const stepContentList = useAppSelector(StepContentListSelector);
  const stepDataList = useAppSelector(StepDataListSelector);
  const matchingProducts = useAppSelector(MatchingProductsSelector);

  const onBackClick = () => {
    if (matchingProducts && matchingProducts.length >= 0) {
      dispatch(clearMatchingProducts());
    }
    dispatch(goBack());
    ResetFitStatusForAllProducts();

    if (!stepDataList.find((sd) => sd.parameters === currentStep))
      dispatch(setFitStatus(FitAssistantStatus.NoSelection));

    if (hasError) dispatch(setHasError(false));
  };

  const onClose = () => {
    trackClose(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);

    dispatch(setShowFitAssistant(false));
    dispatch(setShowMoreInfo(false));

    if (
      fitStatus === FitAssistantStatus.Confirmed ||
      currentStep === FitAssistantStep.NoFits ||
      currentStep === FitAssistantStep.OtherFits
    )
      dispatch(setShowResetButtons(true));
  };

  return (
    <>
      {!showMoreInfo && (
        <div className="fit-assistant-modal-header flex-column px-6 px-lg-9 mt-6">
          <div className="buttons w-100">
            <button
              className={`btn fit-guide-modal-back ${stepContentList[0]?.Parameter === currentStep ? 'invisible' : ''}`}
              onClick={onBackClick}
            >
              <span>
                <i className="le-icon-arrow"></i>
              </span>
              <span className="sr-only">Back</span>
            </button>
            <button className="btn hide-fit-guide-modal" onClick={onClose}>
              <span>
                <i className="le-icon-x"></i>
              </span>
              <span className="sr-only">Close fit assistant</span>
            </button>
          </div>
          <div className="info d-flex flex-column w-100">
            <span className="selection mt-6 mb-3 display-block">
              {carInformation
                .filter(
                  (x) =>
                    x.parameterName === FitAssistantStep.Make ||
                    x.parameterName === FitAssistantStep.Model ||
                    x.parameterName === FitAssistantStep.Year
                )
                .map((ci) => ci.text)
                .join(' ') || <>&nbsp;</>}
            </span>
            <h3 className="heading3 header mb-0">
              {stepContentList?.find((x) => x.Parameter === currentStep)?.Headline}
            </h3>
            <ProgressBar />
          </div>
        </div>
      )}
      {showMoreInfo && (
        <div className="fit-assistant-modal-header flex-column px-6 px-lg-9 mt-6">
          <div className="buttons w-100">
            <button className="btn fit-guide-modal-back invisible">
              <span>
                <i className="le-icon-arrow"></i>
              </span>
              <span className="sr-only">Back</span>
            </button>
            <button className="btn hide-fit-guide-modal" onClick={onClose}>
              <span>
                <i className="le-icon-x"></i>
              </span>
              <span className="sr-only">Close fit assistant</span>
            </button>
          </div>
          <div className="info d-flex flex-column w-100">
            <span className="selection mt-6 mb-3"></span>
            <h3 className="heading3 header mb-0">
              {stepContentList?.find((x) => x.Parameter === currentStep)?.MoreInfoContent?.Header}
            </h3>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
