enum FitAssistantStep {
  Mounting = 'mounting',
  RackMounting = 'rackmounting',
  Make = 'make',
  Model = 'model',
  Year = 'year',
  Variation = 'variation',
  OtherFits = 'otherfits',
  NoFits = 'nofits',
  GenericRoofRacks = 'genericroofracks',
  RoofRacks = 'roofracks',
  VanSize = 'vansize',
  Unconfirmed = 'unconfirmed',
  NoRackFit = 'norackfit',
  RackNotCompatible = 'racknotcompatible',
  EnsureThirdParty = 'ensurethirdparty',
  CarSeatFit = 'carseatfit',
  ConfirmCompatibility = 'confirmCompatibility',
}

export default FitAssistantStep;
