import React from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { setShowResetButtons, setShowFitAssistant } from '../../Slices/FitAssistantSlice';
import { trackClose } from '../../Analytics/Adobe';
import {
  CarInformationSelector,
  CurrentStepSelector,
  FitStatusSelector,
  InitialDataSelector,
  ShowFitAssistantSelector,
} from '../../Selectors/FitAssistantSelectors';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';

import './ModalBackdrop.less';

const ModalBackdrop = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const fitStatus = useAppSelector(FitStatusSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const showFitAssistant = useAppSelector(ShowFitAssistantSelector);

  const onClose = () => {
    trackClose(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);

    dispatch(setShowFitAssistant(false));

    if (
      fitStatus === FitAssistantStatus.Confirmed ||
      currentStep === FitAssistantStep.NoFits ||
      currentStep === FitAssistantStep.OtherFits
    )
      dispatch(setShowResetButtons(true));
  };

  return (
    <>
      <div
        className={`fit-assistant-modal-left-slide-in-backdrop ${showFitAssistant ? 'active' : ''}`}
        onClick={onClose}
      ></div>
    </>
  );
};

export default ModalBackdrop;
