import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../Store/store';
import { Constants } from '../Constants';

export const MatchingProductsSelector = (state: RootState) => state.MatchingProducts.matchingProducts;
export const SolutionComponentsSelector = (state: RootState) => state.MatchingProducts.solutionComponents;

export const FitTipsSelector = createSelector(
  (state) => state,
  (state: RootState) => {
    return (
      state.MatchingProducts.matchingProducts
        ?.find((x) => x.FitTips?.length > 0)
        ?.FitTips.filter((x) => x.FitTipClassification === Constants.FitTipClassifications.VEHICLE_SPECIFIC) ?? []
    );
  }
);
