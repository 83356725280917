import React, { PropsWithChildren, createRef, useEffect } from 'react';
import { useAppSelector } from '../../Store/store';
import { ShowFitAssistantSelector } from '../../Selectors/FitAssistantSelectors';
import Header from '../Header/Header';

import './ModalContent.less';

const ModalContent = ({ children }: PropsWithChildren) => {
  const showFitAssistant = useAppSelector(ShowFitAssistantSelector);

  const modalbodyRef = createRef<HTMLDivElement>();

  useEffect(() => {
    modalbodyRef?.current?.scrollTo(0, 0);
  }, [children]);

  return (
    <>
      <div className={`fit-assistant-modal on-top ${showFitAssistant ? 'wrapper-visible' : ''}`}>
        <header>
          <Header />
        </header>
        <section className="modal-content-section position-relative d-inline-block h-100" ref={modalbodyRef}>
          <div className="fit-assistant-modal-body">{children}</div>
        </section>
        <div id="buttonPortal"></div>
      </div>
    </>
  );
};

export default ModalContent;
