import { DocumentNode, gql } from '@apollo/client';

export const GET_PRODUCT_QUERY: DocumentNode = gql`
  query Get(
    $siteName: String!
    $ispreview: Boolean!
    $language: String!
    $configurationItem: String!
    $parameters: String!
    $selectedproductpimid: String!
    $additionalFilter: String
  ) {
    getCarSelectorProducts(
      siteName: $siteName
      ispreview: $ispreview
      language: $language
      configurationItem: $configurationItem
      parameters: $parameters
      selectedproductpimid: $selectedproductpimid
      additionalFilter: $additionalFilter
    ) {
      carSelectorProductsResponse
    }
  }
`;

export const GET_OPTIONS_QUERY: DocumentNode = gql`
  query Get(
    $siteName: String!
    $language: String!
    $configurationItem: String!
    $parameters: String!
    $additionalFilter: String
  ) {
    getCarSelectorOptions(
      siteName: $siteName
      language: $language
      configurationItem: $configurationItem
      parameters: $parameters
      additionalFilter: $additionalFilter
    ) {
      carSelectorOptionsResponse
    }
  }
`;
