import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepDataSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowMoreInfoSelector,
  ShowResetButtonsSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { getOptions, getProducts } from '../../Thunks/Queries';
import { resetFitAssistant } from '../../Thunks/Reset';
import { addCarInformation, selectOption, setShowResetButtons, setStepEnum } from '../../Slices/FitAssistantSlice';
import { SetFitStatusForAllProducts } from '../../../FitAssistantShared/Functions';
import { StepDataData, CarInformation } from '../../Types/FitAssistantData';
import { trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import ExtraRackOptionItem from './ExtraRackOptionItem';
import FitAssistantRooftopTentStatus from '../../Enums/FitAssistantRooftopTentStatus';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import PrimaryButton from '../Buttons/PrimaryButton';
import ProductSearchType from '../../Enums/ProductSearchType';
import RackOptionItem from './RackOptionItem';
import SecondaryButton from '../Buttons/SecondaryButton';

import './RackSelector.less';

const GenericRackSelector = () => {
  const dispatch = useAppDispatch();
  const carInformation = useAppSelector(CarInformationSelector);
  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const currentStepData = useAppSelector(CurrentStepDataSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const translations = useAppSelector(TranslationsSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const showResetbButton = useAppSelector(ShowResetButtonsSelector);

  useEffect(() => {
    if (!currentStepData?.data || currentStepData?.data?.length === 0) {
      dispatch(getOptions());
    }
  }, []);

  const getCarInformation = (option: StepDataData): CarInformation[] => {
    const configItem = currentStepData?.configItems;
    const variation: CarInformation = {
      parameterName: currentStep,
      configurationItem: configItem ?? '',
      text: option.Text,
      value: option.Value,
    };

    let filteredCarinformation = carInformation.filter((x) => x.parameterName != currentStep);
    let updCarInformation: CarInformation[] = [...filteredCarinformation, variation];
    return updCarInformation;
  };

  const handleOnClick = (): void => {
    let selectedOption = currentStepData?.data?.find((sd) => sd.Selected);
    let hasConfirmedCompatibility =
      carInformation.find((x) => x.parameterName === FitAssistantStep.ConfirmCompatibility) !== undefined;

    if (
      selectedOption?.CompatibleRackForTent === FitAssistantRooftopTentStatus.Confirmed &&
      (selectedOption?.StructurallySupported === FitAssistantRooftopTentStatus.Confirmed ||
        hasConfirmedCompatibility ||
        !selectedOption?.StructurallySupported)
    ) {
      dispatch(getProducts(ProductSearchType.GenericRackSelector));
    } else {
      dispatch(setStepEnum(FitAssistantStep.RackNotCompatible));
      SetFitStatusForAllProducts(FitAssistantStatus.NoFit);
    }

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  return (
    <>
      {!showMoreInfo && (
        <>
          <div className="rack-selector d-flex flex-column justify-content-center">
            <div
              className="info-text"
              dangerouslySetInnerHTML={{
                __html: currentStepContent?.Text ?? '',
              }}
            />
            <h5>{translations.selectRoofRack}</h5>
            <div className="rack-options mt-6">
              {currentStepData?.data?.map((option, index) => {
                return (
                  <RackOptionItem
                    onClick={() => {
                      if (!option.Selected) {
                        dispatch(selectOption(index));
                        dispatch(setShowResetButtons(false));
                        dispatch(addCarInformation(getCarInformation(option)));
                      }
                    }}
                    option={option}
                    key={index}
                  />
                );
              })}
              <ExtraRackOptionItem
                onClick={() => {
                  dispatch(setStepEnum(FitAssistantStep.RoofRacks));
                }}
                text={translations.iNeedARoofrack}
              />
              <div className="footer-link">
                <a
                  className="w-100 text-center"
                  onClick={() => {
                    dispatch(setStepEnum(FitAssistantStep.EnsureThirdParty));
                  }}
                >
                  {translations.iHaveAnotherRoofrack}
                </a>
              </div>
            </div>
          </div>
          <Buttons visible={currentStepData?.data?.some((x) => x.Selected) ?? false}>
            <PrimaryButton visible text={translations.confirm} onClick={handleOnClick} />
            <SecondaryButton
              visible={showResetbButton}
              text={translations.reset}
              onClick={() => dispatch(resetFitAssistant())}
            />
          </Buttons>
        </>
      )}
    </>
  );
};

export default GenericRackSelector;
