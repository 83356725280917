import React, { useEffect } from 'react';
import ModalContent from './Components/Modal/ModalContent';
import ModalBackdrop from './Components/Modal/ModalBackdrop';
import PortalContainer from './Components/FitAssistantButton/PortalContainer';
import ErrorComponent from './Components/Error/Error';
import { useAppDispatch, useAppSelector } from './Store/store';
import {
  CurrentStepContentSelector,
  HasErrorSelector,
  InitialDataSelector,
  ShowFitAssistantSelector,
  ShowMoreInfoSelector,
} from './Selectors/FitAssistantSelectors';
import { loadOptionsFromCookie } from './Thunks/LoadOptionsFromCookie';
import Footer from './Components/Footer/Footer';
import MoreInformation from './Components/MoreInformation/MoreInformation';
import FitAssistantVariation from './Enums/FitAssistantVariation';
import Awnings from './Variants/Awnings';
import CarRoofRacks from './Variants/CarRoofRacks';
import CarSeats from './Variants/CarSeats';
import DogCrates from './Variants/DogCrates';
import RoofPlatforms from './Variants/RoofPlatforms';
import RooftopTents from './Variants/RooftopTents';
import TruckBedBikeRacks from './Variants/TruckBedBikeRacks';
import TruckRackSystem from './Variants/TruckRackSystems';
import TrunkAndSpareTireBikeRacks from './Variants/TrunkAndSpareTireBikeRacks';
import VanRackSystems from './Variants/VanRackSystems';

const FitAssistant = () => {
  const dispatch = useAppDispatch();
  const hasError = useAppSelector(HasErrorSelector);
  const showFitAssistant = useAppSelector(ShowFitAssistantSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const initialData = useAppSelector(InitialDataSelector);

  const html: HTMLElement = document.querySelector('html')!;
  const body: HTMLElement = document.querySelector('body')!;

  const getVariationComponent = () => {
    switch (initialData.FitAssistantVariation) {
      case FitAssistantVariation.Awnings:
        return <Awnings />;
      case FitAssistantVariation.CarRoofRacks:
        return <CarRoofRacks />;
      case FitAssistantVariation.CarSeats:
        return <CarSeats />;
      case FitAssistantVariation.DogCrates:
        return <DogCrates />;
      case FitAssistantVariation.RoofPlatforms:
        return <RoofPlatforms />;
      case FitAssistantVariation.RooftopTents:
        return <RooftopTents />;
      case FitAssistantVariation.TruckBedBikeRacks:
        return <TruckBedBikeRacks />;
      case FitAssistantVariation.TruckRackSystem:
        return <TruckRackSystem />;
      case FitAssistantVariation.TruckAndSpareTireBikeRacks:
        return <TrunkAndSpareTireBikeRacks />;
      case FitAssistantVariation.VanRackSystems:
        return <VanRackSystems />;
      default:
        return <></>;
    }
  };

  const ensureLoad = async () => {
    await dispatch(loadOptionsFromCookie());
  };

  useEffect(() => {
    ensureLoad();
  }, []);

  useEffect(() => {
    if (showFitAssistant) {
      html.classList.add('overflow-hidden');
      body.classList.add('overflow-hidden');
    } else {
      html.classList.remove('overflow-hidden');
      body.classList.remove('overflow-hidden');
    }
  }, [showFitAssistant]);

  return (
    <>
      <PortalContainer />
      <ModalContent>
        {showFitAssistant && getVariationComponent()}
        {showMoreInfo && <MoreInformation />}
        {hasError && <ErrorComponent />}
        {!hasError && !showMoreInfo && currentStepContent?.MoreInfoContent && <Footer />}
      </ModalContent>
      <ModalBackdrop />
    </>
  );
};

export default FitAssistant;
