import { AdobeAnalyticsUtil } from '../../../../../../../../../Foundation/Common/code/Views/Foundation/Common/AdobeAnalytics/AdobeAnalyticsUtil';
import { CarData, FitAssistantEventObject, FitAssistantEventData } from '../Types/Tracking';
import { CarInformation } from '../Types/FitAssistantData';
import { Constants } from '../Constants';
import FitAssistantStep from '../Enums/FitAssistantStep';
import FitAssistantVariation from '../Enums/FitAssistantVariation';

declare global {
  interface Window {
    adobeDataLayer: any;
    dataLayer: any;
  }
}

export const trackOpen = (
  fitAssistantVariation: FitAssistantVariation,
  isYearFirst: boolean,
  fitAssistantStep: FitAssistantStep,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation, fitAssistantStep, true);
  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.OPEN_WITH_SELECTION, eventObject);
};

export const trackOpenEmpty = (
  fitAssistantVariation: FitAssistantVariation,
  isYearFirst: boolean,
  carInformation: CarInformation[],
  fitAssistantStep: FitAssistantStep
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation, fitAssistantStep, true);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.OPEN_WITHOUT_SELECTION, eventObject);
};

export const trackClose = (
  fitAssistantVariation: FitAssistantVariation,
  fitAssistantStep: FitAssistantStep,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation, fitAssistantStep, true);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.EXIT, eventObject);
};

export const trackNoFit = (
  fitAssistantVariation: FitAssistantVariation,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.CLOSE_NOFIT, eventObject);
};

export const trackFit = (
  fitAssistantVariation: FitAssistantVariation,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.CLOSE_CONFIRMATION, eventObject);
};

export const trackChooseVariation = (
  fitAssistantVariation: FitAssistantVariation,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation);

  eventObject.fit_assistant.selectedfilter = '';

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.CHOOSE_VARIATION, eventObject);
};

export const trackOK = (
  fitAssistantVariation: FitAssistantVariation,
  fitAssistantStep: FitAssistantStep,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation, fitAssistantStep, true);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.OK, eventObject);
};

export const trackReset = (
  fitAssistantVariation: FitAssistantVariation,
  fitAssistantStep: FitAssistantStep,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation, fitAssistantStep, true);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.RESET, eventObject);
};

export const trackView = (
  fitAssistantVariation: FitAssistantVariation,
  fitAssistantStep: FitAssistantStep,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation, fitAssistantStep, true);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.VIEW, eventObject);
};

export const trackUnconfirmed = (
  fitAssistantVariation: FitAssistantVariation,
  fitAssistantStep: FitAssistantStep,
  isYearFirst: boolean,
  carInformation: CarInformation[]
): void => {
  let eventObject = getEventObject(fitAssistantVariation, isYearFirst, carInformation, fitAssistantStep, true);

  AdobeAnalyticsUtil.DataLayer.TrackEvent(Constants.Tracking.CLOSE_UNCONFIRMED, eventObject);
};

const getEventObject = (
  fitAssistantVariation: FitAssistantVariation,
  isYearFirst: boolean,
  carInformation?: CarInformation[],
  fitAssistantStep?: FitAssistantStep,
  includeHeadline?: boolean
): FitAssistantEventObject => {
  let { brand, model, year, carVariation } = getCarData(isYearFirst, carInformation);

  let fitAssistant: FitAssistantEventData = {
    fatype: window.dataLayer[0]?.gaAggregate ?? '',
    pagetype: window.adobeDataLayer ? window.adobeDataLayer.page.type : '',
    ordervariation: isYearFirst ? 'ybmr' : 'bmyr',
    brand: brand ?? '',
    model: model ?? '',
    year: year ?? '',
    carvariation: carVariation ?? '',
    selectedfilter: getSelectedFilter(brand, model, year, carVariation, fitAssistantVariation),
  };

  if (includeHeadline) {
    fitAssistant.title = getFitAssistantStepName(fitAssistantStep);
  } else {
    delete fitAssistant.title;
  }

  let eventObject: FitAssistantEventObject = { fit_assistant: fitAssistant };

  return eventObject;
};

const getFitAssistantStepName = (fitAssistantStep: FitAssistantStep | undefined): string => {
  let currentKeyByValue = Object.entries(FitAssistantStep).find((entry) => entry[1] === fitAssistantStep);

  if (!currentKeyByValue) return '';

  return currentKeyByValue[0].match(/[A-Z][a-z]+/g)?.join(' ') ?? '';
};

const getCarData = (isYearFirst: boolean, carInformation: CarInformation[] | undefined): CarData => {
  if (!carInformation) {
    return {
      brand: '',
      model: '',
      year: '',
      carVariation: '',
    };
  }

  return {
    brand: isYearFirst ? carInformation[1]?.text : carInformation[0]?.text,
    model: isYearFirst ? carInformation[2]?.text : carInformation[1]?.text,
    year: isYearFirst ? carInformation[0]?.text : carInformation[2]?.text,
    carVariation: carInformation[3]?.text,
  };
};

const getSelectedFilter = (
  brand: string,
  model: string,
  year: string,
  carVariation: string,
  fitAssistantVariation: FitAssistantVariation
) => {
  let shortVersions = [
    FitAssistantVariation.CarSeats,
    FitAssistantVariation.DogCrates,
    FitAssistantVariation.TruckBedBikeRacks,
    FitAssistantVariation.TruckAndSpareTireBikeRacks,
  ];

  if ((shortVersions.includes(fitAssistantVariation) && model && year) || carVariation) {
    return `${brand ?? ''} ${model ?? ''} ${year ?? ''} ${carVariation ?? ''}`.trim();
  }

  return '';
};
