import React from 'react';
import { StepDataData } from '../../Types/FitAssistantData';

type RackOptionProps = {
  option: StepDataData;
  onClick: () => void;
};
const RackOptionItem = ({ option, onClick }: RackOptionProps) => {
  return (
    <div className={`rack-option d-flex mb-4 px-1 py-1 ${option.Selected ? 'selected' : ''}`} onClick={onClick}>
      <div className="image-wrapper d-flex justify-content-center w-100">
        {option.Image && <img src={option.Image} alt={option.AltText!}></img>}
      </div>
      <div className="info d-flex flex-column justify-content-center">
        <h6 className="mb-1">{option.Text}</h6>
        <p className={`${!option.Description ? 'd-none' : ''}`}>{option.Description}</p>
        <h6 className={`${!option.FormattedPrice ? 'd-none' : ''}`}>+ {option.FormattedPrice}</h6>
      </div>
    </div>
  );
};

export default RackOptionItem;
