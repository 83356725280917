import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { InitialDataSelector, ShowFitGuideModalSelector } from '../../Selectors/FitAssistantSelectors';
import { setShowFitGuideModal } from '../../Slices/FitAssistantSlice';

const FitGuideModal = () => {
  const html: HTMLHtmlElement = document.querySelector('html')!;
  const body: HTMLBodyElement = document.querySelector('body')!;

  const dispatch = useAppDispatch();
  const initialData = useAppSelector(InitialDataSelector);
  const showFitGuideModal = useAppSelector(ShowFitGuideModalSelector);

  useEffect(() => {
    if (showFitGuideModal) {
      html.classList.add('overflow-hidden');
      body.classList.add('overflow-hidden');
    } else {
      html.classList.remove('overflow-hidden');
      body.classList.remove('overflow-hidden');
    }
  }, [showFitGuideModal]);

  return (
    <div className="my-3 fit-guide">
      <div
        onClick={() => dispatch(setShowFitGuideModal(!showFitGuideModal))}
        className={`fit-guide-left-slide-in-menu-backdrop backdrop ${showFitGuideModal ? 'active' : ''}`}
      ></div>
      <div className={`fit-guide-menu left-slide-in-menu-wrapper  ${showFitGuideModal ? '' : 'wrapper-hidden'}`}>
        <section className="menu-content-section d-inline-block">
          <div className="menu-buttons slide-in-menu-heading justify-content-end">
            <button
              type="button"
              className="top-left-mini-btn hide-fit-guide-overlay d-flex fit-guide-button p-0"
              onClick={() => dispatch(setShowFitGuideModal(false))}
            >
              <i className="le-icon-x"></i>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div>
            <p className="heading4">{initialData.FitGuide.Title}</p>
          </div>
          <div dangerouslySetInnerHTML={{ __html: initialData.FitGuide.Content }} />
        </section>
      </div>
    </div>
  );
};

export default FitGuideModal;
