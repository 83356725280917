import React from 'react';
import { useAppSelector } from '../Store/store';
import { CurrentStepSelector } from '../Selectors/FitAssistantSelectors';
import FitAssistantStep from '../Enums/FitAssistantStep';
import NoFit from '../Components/NoFit/NoFit';
import OtherFits from '../Components/OtherFits/OtherFits';
import SelectList from '../Components/SelectList/SelectList';
import Unconfirmed from '../Components/Unconfirmed/Unconfirmed';

export const TrunkAndSpareTireBikeRacks = () => {
  const currentStep = useAppSelector(CurrentStepSelector);

  const RenderStep = () => {
    switch (currentStep) {
      case FitAssistantStep.Make:
      case FitAssistantStep.Model:
      case FitAssistantStep.Year:
        return <SelectList />;
      case FitAssistantStep.NoFits:
        return <NoFit />;
      case FitAssistantStep.OtherFits:
        return <OtherFits />;
      case FitAssistantStep.Unconfirmed:
        return <Unconfirmed />;
      default:
        return <></>;
    }
  };

  return <>{RenderStep()}</>;
};

export default TrunkAndSpareTireBikeRacks;
