import React from 'react';
import { ButtonVariationProps } from './Types';
import { useAppSelector } from '../../Store/store';
import { StepHasSelectedOptionSelector } from '../../Selectors/FitAssistantSelectors';

const PrimaryButtonVariation = ({ text, onClick }: ButtonVariationProps) => {
  const stepHasSelectedOption = useAppSelector(StepHasSelectedOptionSelector);

  return (
    <button className="btn thule-btn-default w-100" onClick={onClick} disabled={!stepHasSelectedOption}>
      {text}
    </button>
  );
};

export default PrimaryButtonVariation;
