import React from 'react';

type RackOptionProps = {
  text: string;
  onClick: () => void;
};
const ExtraRackOptionItem = ({ text, onClick }: RackOptionProps) => {
  return (
    <div className="rack-option d-flex mb-4 px-1 py-1" onClick={onClick}>
      <div className="image-wrapper d-flex justify-content-center w-100">
        <i className="le-icon-plus"></i>
      </div>
      <div className="info d-flex flex-column justify-content-center">
        <h6 className="mb-1">{text}</h6>
      </div>
    </div>
  );
};

export default ExtraRackOptionItem;
