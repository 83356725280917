import React, { useCallback, useEffect, useState } from 'react';
import { IsProductPage } from '../../../FitAssistantShared/IsProductPage';
import { SetFitStatusForAllProducts, setQueryFromSearchParameters } from '../../../FitAssistantShared/Functions';
import {
  CarInformationSelector,
  CurrentStepContentSelector,
  CurrentStepDataSelector,
  CurrentStepSelector,
  InitialDataSelector,
  ShowMoreInfoSelector,
  TranslationsSelector,
} from '../../Selectors/FitAssistantSelectors';
import { useAppDispatch, useAppSelector } from '../../Store/store';
import { ContentOption } from '../../Types/FitAssistantData';
import {
  setFitStatus,
  setFooterIsFixed,
  setShowFitAssistant,
  setShowResetButtons,
  setStepEnum,
} from '../../Slices/FitAssistantSlice';
import { trackOK } from '../../Analytics/Adobe';
import Buttons from '../Buttons/Buttons';
import FitAssistantStatus from '../../Enums/FitAssistantStatus';
import FitAssistantStep from '../../Enums/FitAssistantStep';
import PrimaryButton from '../Buttons/PrimaryButton';
import ThirdPartyRackOption from './ThirdPartyRackOption';
import { FooterHelper } from '../../Helpers/MoreInfoHelper';
import ThirdPartyRackOptions from './ThirdPartyRackOptions';

const ThirdPartySelector = () => {
  const dispatch = useAppDispatch();

  const currentStep = useAppSelector(CurrentStepSelector);
  const currentStepContent = useAppSelector(CurrentStepContentSelector);
  const stepDataList = useAppSelector(CurrentStepDataSelector);
  const translations = useAppSelector(TranslationsSelector);
  const showMoreInfo = useAppSelector(ShowMoreInfoSelector);
  const initialData = useAppSelector(InitialDataSelector);
  const carInformation = useAppSelector(CarInformationSelector);

  const [selected, setSelected] = useState<ContentOption>();

  useEffect(() => {
    let result = FooterHelper.FooterShouldBeFixed('.rack-selector');
    dispatch(setFooterIsFixed(result));
  }, [stepDataList]);

  const handleOnClick = () => {
    if (selected?.NextStep) {
      dispatch(setStepEnum(selected.NextStep as FitAssistantStep));
    } else {
      SetFitStatusForAllProducts(FitAssistantStatus.Confirmed);
      dispatch(setFitStatus(FitAssistantStatus.Confirmed));
      dispatch(setShowFitAssistant(false));
      dispatch(setShowResetButtons(true));

      if (IsProductPage) {
        setQueryFromSearchParameters(initialData.FitAssistantCookieName, carInformation);
      }
    }

    trackOK(initialData.FitAssistantVariation, currentStep, initialData.IsYearFirst, carInformation);
  };

  const onSelectOption = useCallback((option: ContentOption) => {
    setSelected(option);
  }, []);

  return (
    <>
      {!showMoreInfo && (
        <>
          <div className="rack-selector d-flex flex-column justify-content-center">
            <div
              className="info-text"
              dangerouslySetInnerHTML={{
                __html: currentStepContent?.Text ?? '',
              }}
            />
            <h5>{translations.chooseOption}</h5>
            <ThirdPartyRackOptions
              options={currentStepContent?.ContentOptions ?? []}
              onSelect={onSelectOption}
              selected={selected}
            />
          </div>
          <Buttons visible={selected !== undefined}>
            <PrimaryButton visible text={translations.confirm} onClick={handleOnClick} />
          </Buttons>
        </>
      )}
    </>
  );
};

export default ThirdPartySelector;
