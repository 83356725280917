import React, { ReactEventHandler } from 'react';
import { ContentOption } from '../../Types/FitAssistantData';
import { Constants } from '../../Constants';
import { ImageLoadEvent } from '../../Types/Events';

type MountingOptionProps = {
  option: ContentOption;
  onClick: () => void;
};

const MountingOption = ({ option, onClick }: MountingOptionProps) => {
  const handleImageLoad: ReactEventHandler<HTMLImageElement> = (event) => {
    document.dispatchEvent(
      new CustomEvent<ImageLoadEvent>(Constants.Events.VARIATION_OPTION_IMAGE_LOAD, {
        detail: { height: (event?.target as HTMLImageElement)?.clientHeight },
      })
    );
  };

  return (
    <div className="mounting-option mb-4 px-4 py-4" onClick={onClick}>
      <div className="image-wrapper d-flex justify-content-center w-100 mb-3">
        <img src={option.ImageUrl} alt={option.ImageAlt} onLoad={handleImageLoad} />
      </div>
      <h5 className="mb-2">{option.Title}</h5>
      <p>{option.Description}</p>
    </div>
  );
};

export default MountingOption;
