import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../Store/store';
import { IsProductPage } from '../../FitAssistantShared/IsProductPage';
import { Cookies } from 'typescript-cookie';
import { ResetFitStatusForAllProducts } from '../../FitAssistantShared/Functions';
import { trackReset } from '../Analytics/Adobe';

export const resetFitAssistant = createAsyncThunk<void, void>('fitAssistant/reset', async (_, { getState }) => {
  let state = getState() as RootState;

  let fitAssistant = state.FitAssistant;
  let initialData = fitAssistant.initialData;

  Cookies.remove(initialData.FitAssistantCookieName, {
    path: `/${initialData.Language.toLowerCase()}/`,
  });

  Cookies.remove(initialData.FitAssistantPerCategoryCookieName, {
    path: window.location.pathname
      .split('/')
      .filter((x) => !x.includes('-_-'))
      .join('/'),
  });

  ResetFitStatusForAllProducts();

  if (IsProductPage) {
    let url = new URL(window.location.href);
    url.search = '';
    history.replaceState(null, '', url.toString());
  }

  trackReset(
    initialData.FitAssistantVariation,
    fitAssistant.step,
    initialData.IsYearFirst,
    fitAssistant.carInformation
  );
});
