import React from 'react';

type SeatIndicator = {
  index: number;
  isFit: boolean;
};

const SeatIndicator = ({ index, isFit }: SeatIndicator) => {
  return (
    <span className={`span-seat-${index}`}>
      <i
        className={`le-icon-${isFit ? 'check' : 'x'}-circle position-absolute car-seat seat-${index} ${isFit ? 'fit' : 'no-fit'}`}
      ></i>
    </span>
  );
};

export default SeatIndicator;
