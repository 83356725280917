import React, { useEffect, useState } from 'react';
import { useOnResize } from '../../../../../../../../../../Foundation/React/code/Hooks/UseOnResize';
import { createPortal } from 'react-dom';
import { IsProductPage } from '../../../FitAssistantShared/IsProductPage';
import FitAssistantButton from './FitAssistantButton';

const PortalContainer = () => {
  const [isDOMReady, setIsDOMReady] = useState<boolean>(false);

  const portal = document.querySelector('#REACT_fitAssistant_new_portal');
  const mobilePortal = document.querySelector('#REACT_fitAssistant_new_portal_mobile');

  useEffect(() => {
    setIsDOMReady(true);
  }, []);

  const width: number = useOnResize();
  return (
    <>
      {IsProductPage && isDOMReady && portal ? createPortal(<FitAssistantButton />, portal) : null}
      {!IsProductPage && isDOMReady && portal && mobilePortal
        ? createPortal(<FitAssistantButton />, width <= 992 ? mobilePortal : portal)
        : null}
    </>
  );
};

export default PortalContainer;
